import React, { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Select from "react-select";
import Iconify from "src/components/iconify/Iconify";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { setShowSectionInput } from "src/redux/actions/educationActions";
import { getEducationSection } from "src/pages/education/request";

const styles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "#212B36" : "rgba(145, 158, 171, 0.32)",
    height: "55px",
    borderRadius: "6px",
    zIndex: 9999,
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    maxHeight: "180px",
  }),
  menu: (base) => ({ ...base, zIndex: 9999 }),
};

const AddSection = ({ seletedSection, handleChange, addSection }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loadingButton);
  const sectionInput = useSelector((state) => state.education.showSectionInput);
  const educationSection = useSelector(
    (state) => state.education.educationSections
  );

  useEffect(() => {
    getEducationSection(dispatch);
  }, []);

  const options = educationSection?.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
  }));

  return (
    <>
      <Typography variant="h5" sx={{ mb: 1.5 }}>
        1. Select a Section:
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <FormControl sx={{ minWidth: 300 }}>
          <Select
            options={options}
            defaultValue={
              seletedSection
                ? {
                    id: seletedSection?.id,
                    value: seletedSection?.name,
                    label: seletedSection?.name,
                  }
                : "Select..."
            }
            onChange={handleChange}
            placeholder="Select..."
            styles={styles}
          />
        </FormControl>

        {!sectionInput && (
          <Button
            variant={"contained"}
            startIcon={<Iconify icon="eva:plus-fill" />}
            size="small"
            onClick={() => dispatch(setShowSectionInput(true))}
          >
            Add Section
          </Button>
        )}
      </Stack>
      <Box sx={{ my: 4 }}></Box>
      {sectionInput && (
        <form onSubmit={addSection}>
          <Grid container spacing={2} alignItems="end">
            <Grid item xs={8}>
              <TextField
                label="Add Section"
                name="section"
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                spacing={3}
              >
                <Button
                  variant="contained"
                  color="inherit"
                  size="small"
                  type="button"
                  onClick={() => dispatch(setShowSectionInput(false))}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  loading={loading}
                  loadingIndicator={
                    <CircularProgress size={12} color="inherit" />
                  }
                >
                  Confirm
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default AddSection;
