import React, { useEffect } from "react";
import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { getProcessingTime } from "./request";
import { useDispatch, useSelector } from "react-redux";
import { productData } from "../../users/productStatsConfig";
import { setModalState } from "src/redux/actions/modalActions";
import Iconify from "src/components/iconify/Iconify";
import SvgColor from "src/components/svg-color/SvgColor";
import SetProcessingTime from "src/sections/modals/processingTime/SetProcessingTime";
import UpdateProcessingTime from "src/sections/modals/processingTime/UpdateProcessingTime";
import DeleteProcessingTime from "src/sections/modals/processingTime/DeleteProcessingTime";

const ProcessingTime = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);
  const processingTime = useSelector((state) => state.product.processingTime);
  const catalogList = useSelector((state) => state.orders.catalogList);

  useEffect(() => {
    getProcessingTime(dispatch);
  }, []);

  function getProduct(item) {
    let matchedItem = catalogList?.find((obj) => obj.title === item.type);
    let matchedID = productData?.find((item) => item.id === matchedItem?.id);

    return matchedID;
  }

  return (
    <Paper
      sx={{
        borderRadius: "15px",
        my: "40px",
      }}
      maxWidth={false}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={"wrap"}
        my={3}
      >
        <Typography variant="h4">Processing time</Typography>

        {userType === "Super Admin" && (
          <Button
            variant={"contained"}
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              dispatch(setModalState(<SetProcessingTime />));
            }}
          >
            Add Time
          </Button>
        )}
      </Stack>
      {processingTime?.length ? (
        <>
          {" "}
          <Grid container spacing={2}>
            {processingTime?.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 2,
                    borderRadius: "8px",
                    borderRight: "8px solid #02B2FE",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <SvgColor
                      src={getProduct(item)?.icon}
                      sx={{
                        width: 40,
                        height: 40,
                        color: "primary.main",
                      }}
                    />

                    <Typography variant="body1">
                      {getProduct(item)?.title}
                    </Typography>
                  </Box>

                  <Divider
                    color="#919EAB"
                    orientation="vertical"
                    flexItem
                    sx={{ mx: 2 }}
                  />
                  <Typography variant="body1">
                    {item.processing_time}
                  </Typography>
                  {userType === "Super Admin" && (
                    <Stack direction={"row"} justifyContent={"flex-end"}>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          dispatch(
                            setModalState(<UpdateProcessingTime item={item} />)
                          );
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          dispatch(
                            setModalState(<DeleteProcessingTime id={item.id} />)
                          );
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Stack>
                  )}
                </Paper>
              </Grid>
            ))}{" "}
          </Grid>
        </>
      ) : (
        <>
          <Paper
            elevation={3}
            sx={{ width: "100%", p: 4, textAlign: "center" }}
          >
            <Typography variant="h6" paragraph>
              No Processing Time Available
            </Typography>
          </Paper>
        </>
      )}
    </Paper>
  );
};

export default ProcessingTime;
