import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loadingBtnAction } from "src/redux/actions/userActions";
import request from "src/utils/request";
import { getProductList } from "../../../pages/products/request";
import { toastify } from "src/utils/toast";
import { setModalState } from "src/redux/actions/modalActions";
import UploadImage from "src/components/upload-image/UploadImage";

const LinkEtsySKU = ({ product, page, rowsPerPage }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loadingButton);

  const [previewImage, setPreviewImage] = useState([]);

  const [graphicImage, setGraphicImage] = useState([]);
  const [graphicURL, setGraphicURL] = useState("");

  const [noteCardURL, setNoteCardURL] = useState("");
  const [noteCardImage, setNoteCardImage] = useState([]);

  const getFormValue = (e) => {
    e.preventDefault();
    const listingID = e.target.elements.listingID.value;
    const sku = e.target.elements.sku.value;

    let formData = new FormData();
    formData.append("catalog_id", product?.catalog?.id);
    formData.append("product_id", product?.id);
    formData.append("listing_id", listingID);
    formData.append("sku", sku);

    if (previewImage?.length) {
      formData.append("preview_image", previewImage[0]);
    }

    if (graphicImage?.length) {
      formData.append("graphic_image", graphicImage[0]);
    } else {
      formData.append("url", graphicURL);
    }

    if (product?.catalog?.no_of_graphics === 2) {
      if (noteCardImage?.length) {
        formData.append("notecard_image", noteCardImage[0]);
      } else {
        formData.append("notecard_url", noteCardURL);
      }
    }

    linkProductSKU(formData);
  };

  const linkProductSKU = async (payload) => {
    dispatch(loadingBtnAction(true));

    try {
      const res = await request.post("/products/link/etsy/sku", payload);
      if (res) {
        dispatch(loadingBtnAction(false));
        toastify("success", res.data.message);
        dispatch(setModalState(undefined));
        getProductList(dispatch, page + 1, rowsPerPage);
      }
    } catch (e) {
      dispatch(loadingBtnAction(false));
      toastify("error", e.response.data.message);
    }
  };

  return (
    <Box sx={{ width: "90%", margin: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Link an Etsy SKU
      </Typography>
      <form onSubmit={(e) => getFormValue(e)}>
        <Box sx={{ my: 2 }}></Box>
        <TextField
          fullWidth
          type="text"
          label="Product Listing ID"
          defaultValue={product?.listing_id}
          disabled
          name="listingID"
          required
        />
        <Box sx={{ my: 4 }}></Box>
        <TextField
          fullWidth
          type="text"
          label="Product SKU"
          name="sku"
          required
        />

        <Box sx={{ my: 4 }}></Box>
        <Typography variant="h5" sx={{ mb: 1.5 }}>
          1. Add a Preview Image: (optional)
        </Typography>

        <UploadImage
          state={previewImage}
          setState={setPreviewImage}
          disable={false}
          clearState={() => setPreviewImage([])}
          showImage
        />

        <Box sx={{ my: 4 }}></Box>
        <Typography variant="h5" sx={{ mb: 1.5 }}>
          2. Add a Graphic Image:
        </Typography>

        <UploadImage
          state={graphicImage}
          setState={setGraphicImage}
          disable={graphicURL?.length ? true : false}
          clearState={() => setGraphicImage([])}
          showImage
        />

        <Box sx={{ my: 4 }}>
          <Divider>OR</Divider>
        </Box>
        <Typography variant="h5" sx={{ mb: 1.5 }}>
          Add a Graphic URL:
        </Typography>

        <TextField
          label="Graphic URL"
          name="media"
          variant="outlined"
          onChange={(e) => setGraphicURL(e.target.value)}
          disabled={graphicImage?.length ? true : false}
          fullWidth
        />

        {product?.catalog?.no_of_graphics === 2 && (
          <>
            {" "}
            <Box sx={{ my: 4 }}></Box>{" "}
            <Typography variant="h5" sx={{ mb: 1.5 }}>
              3. Add a NoteCard Image:
            </Typography>
            <UploadImage
              state={noteCardImage}
              setState={setNoteCardImage}
              disable={noteCardURL?.length ? true : false}
              clearState={() => setNoteCardImage([])}
              showImage
            />
            <Box sx={{ my: 4 }}>
              <Divider>OR</Divider>
            </Box>
            <Typography variant="h5" sx={{ mb: 1.5 }}>
              Add a NoteCard URL:
            </Typography>
            <TextField
              label="NoteCard URL"
              name="media"
              variant="outlined"
              onChange={(e) => setNoteCardURL(e.target.value)}
              disabled={noteCardImage?.length ? true : false}
              fullWidth
            />
          </>
        )}

        <Box sx={{ my: 4 }}></Box>
        <LoadingButton
          sx={{ borderRadius: "16" }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
          loadingIndicator={<CircularProgress size={16} color="inherit" />}
        >
          Link Product SKU
        </LoadingButton>
      </form>
    </Box>
  );
};

export default LinkEtsySKU;
