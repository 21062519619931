import React from "react";
import Iconify from "src/components/iconify/Iconify";
import StyledButton from "src/components/button/StyledButton";
import LinkEtsyProduct from "src/sections/modals/products/LinkEtsyProduct";
import { useDispatch } from "react-redux";
import { unLinkProduct } from "../utils";
import { setModalState } from "src/redux/actions/modalActions";

const LinkToEtsy = ({ listingID, id }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    if (listingID === null) {
      dispatch(
        setModalState(<LinkEtsyProduct id={id} page={1} rowsPerPage={25} />)
      );
    } else {
      unLinkProduct(dispatch, id, "etsy", 1, 25);
    }
  };
  return (
    <StyledButton
      title={listingID === null ? "Link To Etsy" : "Unlink Etsy product"}
      sx={{
        bgcolor: "#f2521b",
        "&:hover": {
          bgcolor: "#F2441B",
        },
      }}
      startIcon={<Iconify icon="la:etsy" />}
      onClick={onClick}
    />
  );
};

export default LinkToEtsy;
