import React, { useEffect, useState } from "react";
import CartItem from "./CartItem";
import request from "src/utils/request";
import emptycart from "../../assets/emptycart.jpg";
import { toastify } from "src/utils/toast";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CartContainer, StyledGrid } from "./Cart.styles";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { addProductToCart } from "src/redux/actions/productActions";
import { loadingAction, setActiveStep } from "src/redux/actions/userActions";

const Cart = () => {
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = useState([]);
  const productsInCart = useSelector((state) => state.product.productsInCart);

  const CARTITEMS = cartItems?.map((item) => {
    return {
      catalog: item?.catalog,
      id: item?.id,
      title: item?.catalog
        ? item?.catalog.title
        : item?.description.substring(0, 10),
      quantity: item?.quantity,
      price: item?.price,
      image: item?.graphic_image,
      notecard_image: item?.notecard_image,
    };
  });

  useEffect(() => {
    getCartItems();
  }, []);

  const getCartItems = async () => {
    dispatch(loadingAction(true));
    try {
      const res = await request.get(`/cart`);

      if (res) {
        setCartItems(res.data.data);
        dispatch(loadingAction(false));
      }
    } catch (e) {
      if (e.response.data.message === "Cart is empty") {
        dispatch(addProductToCart(0));
      }

      dispatch(loadingAction(false));
    }
  };

  const deleteItem = async (id) => {
    dispatch(loadingAction(true));
    try {
      const res = await request.delete(`/cart/${id}`);

      if (res) {
        dispatch(loadingAction(false));
        dispatch(addProductToCart(productsInCart - 1));

        toastify("success", res.data.message);
      }
    } catch (e) {
      dispatch(loadingAction(false));
      toastify("error", e.response.data.message);
    }
  };

  const addToCart = (clickedItem) => {
    setCartItems((prev) => {
      const isItemInCart = prev?.find((item) => item.id === clickedItem.id);

      if (isItemInCart) {
        updateCart(clickedItem.id, clickedItem.quantity + 1);
        return prev.map((item) =>
          item.id === clickedItem.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [...prev, { ...clickedItem, quantity: 1 }];
    });
  };

  const removeFromCart = (id) => {
    setCartItems((prev) =>
      prev?.reduce((acc, item) => {
        if (item.id === id) {
          if (item.quantity === 1) {
            deleteItem(id);
            return acc;
          }

          updateCart(id, item.quantity - 1);
          return [...acc, { ...item, quantity: item.quantity - 1 }];
        } else {
          return [...acc, item];
        }
      }, [])
    );
  };

  const updateCart = async (id, quantity) => {
    let formData = new FormData();

    formData.append("catalog_id", id);
    formData.append("quantity", quantity);

    try {
      const res = await request.post(`/cart/${id}?_method=PATCH`, formData);

      if (res) {
      }
    } catch (e) {
      toastify("error", e.response.data.message);
    }
  };

  const calculateTotal = (items) =>
    items?.reduce((acc, item) => acc + item.quantity * item.price, 0);

  return (
    <CartContainer>
      <Typography variant="h4" mb={3}>
        Cart
      </Typography>
      <StyledGrid container>
        {cartItems?.length === 0 ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "20vh",
            }}
          >
            <img height={160} width={160} src={emptycart} alt="wallet icon" />
            <Typography variant="h4">Your Cart is Empty!</Typography>
            <Typography variant="caption" mt={1}>
              Continue shopping to add customized products.
            </Typography>
          </Grid>
        ) : (
          CARTITEMS?.map((item, index) => (
            <CartItem
              key={index}
              item={item}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
            />
          ))
        )}
      </StyledGrid>
      <Typography variant="h4" mt={3}>
        Total: ${CARTITEMS ? calculateTotal(CARTITEMS).toFixed(2) : 0}
      </Typography>

      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        spacing={2}
        flexWrap={"wrap"}
      >
        <NavLink to={"/dashboard/products"}>
          <Button
            color="inherit"
            variant="contained"
            onClick={() => {
              dispatch(setActiveStep(1));
            }}
          >
            Continue Shopping
          </Button>
        </NavLink>

        <Button
          color="primary"
          variant="contained"
          disabled={cartItems?.length > 0 ? false : true}
          onClick={() => {
            dispatch(setActiveStep(1));
          }}
        >
          Proceed to Shipping
        </Button>
      </Stack>
    </CartContainer>
  );
};

export default Cart;
