import request from "src/utils/request";
import imgData from "../../assets/logo/logo.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { toastify } from "src/utils/toast";
import { loadingAction } from "src/redux/actions/userActions";
import { calculateAmount } from "./utils";

function calculateSubTotal(productList) {
  let totalItemPrice = 0;
  for (let i = 0; i < productList.length; i++) {
    let itemPrice = parseFloat(productList[i][2].replace("$", ""));
    totalItemPrice += itemPrice;
  }
  return parseFloat(totalItemPrice).toFixed(2);
}

function calculateShipping(productList) {
  let totalShippingCost = 0;
  for (let i = 0; i < productList.length; i++) {
    let shippingCost = parseFloat(productList[i][3].replace("$", ""));
    totalShippingCost += shippingCost;
  }
  return totalShippingCost;
}

function calculateBulkDiscount(orderItems) {
  const discounts = {
    tumbler: {
      threshold: 3,
      initialPrice: 5.83,
      discountedPrice: 4.83,
      additionalDiscountedPrice: 3.83,
    },
    ornament: {
      initialPrice: 4.2,
      additionalDiscountedPrice: 1.99,
    },
    jewelry: {
      initialPrice: 4.2,
      additionalDiscountedPrice: 1.99,
    },
  };

  // Initialize array to store result
  const result = [];

  const tumblers = orderItems.filter((item) => item?.catalog_id === 5);

  // Iterate through order items
  orderItems.forEach((item, index) => {
    const { title, description, price, shipping_price } = item;

    // Determine catalog type and apply discount accordingly
    let catalogType = "";
    if (item?.catalog_id === 5) {
      catalogType = "tumbler";
    } else if (item?.catalog_id === 6) {
      catalogType = "ornament";
    } else if (item?.catalog_id === 27) {
      catalogType = "jewelry";
    } else {
      catalogType = "other";
    }

    // Apply bulk discount based on quantity only to the last shipping price
    let quantityDiscountedPrice = 0;

    if (
      discounts[catalogType] &&
      catalogType === "tumbler" &&
      tumblers?.length > discounts.tumbler.threshold
    ) {
      quantityDiscountedPrice = discounts.tumbler.additionalDiscountedPrice;
    } else if (
      discounts[catalogType] &&
      catalogType === "tumbler" &&
      tumblers?.length == discounts.tumbler.threshold
    ) {
      quantityDiscountedPrice = discounts.tumbler.discountedPrice;
    } else if (
      discounts[catalogType] &&
      catalogType === "tumbler" &&
      tumblers?.length < discounts.tumbler.threshold
    ) {
      quantityDiscountedPrice = discounts.tumbler.initialPrice;
    }

    if (catalogType === "ornament") {
      if (index === 0) {
        quantityDiscountedPrice = discounts.ornament.initialPrice;
      } else {
        quantityDiscountedPrice = discounts.ornament.additionalDiscountedPrice;
      }
    }

    if (catalogType === "jewelry") {
      if (index === 0) {
        quantityDiscountedPrice = discounts.jewelry.initialPrice;
      } else {
        quantityDiscountedPrice = discounts.jewelry.additionalDiscountedPrice;
      }
    }

    if (catalogType === "other") {
      quantityDiscountedPrice = parseFloat(shipping_price);
    }

    const orderItemResult = [
      title,
      description,
      `$${price}`,
      `$${quantityDiscountedPrice.toFixed(2)}`,
    ];
    result.push(orderItemResult);
  });

  return result;
}

const formatPDF = (params, order) => {
  const pdf = new jsPDF();

  var company_logo = {
    w: 40,
    h: 10,
  };

  pdf.addImage(imgData, "PNG", 10, 10, company_logo.w, company_logo.h);
  const tableData = calculateBulkDiscount(params[0]?.items);

  const itemPrice = calculateSubTotal(tableData);
  const shippingPrice = calculateShipping(tableData);
  const grandTotal = order ? calculateAmount(order) : 0;

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(12);
  pdf.text("Receipt no:", 120, 15);
  pdf.setFont("helvetica", "normal");
  pdf.text(params[0].receipt_number, 160, 15);

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(12);
  pdf.text("Date of purchase:", 120, 20);
  pdf.setFont("helvetica", "normal");
  pdf.text(params[0].date_of_purchase, 160, 20);

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(16);
  pdf.text("Inner Circle Prints", 10, 40);

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(12);
  pdf.text("Order ID #:", 10, 50);
  pdf.setFont("helvetica", "normal");
  pdf.text(params[0].order_id.toString(), 50, 50);

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(12);
  pdf.text("Customer name:", 10, 57);
  pdf.setFont("helvetica", "normal");
  pdf.text(params[0].customer_name, 50, 57);

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(12);
  pdf.text("Seller name:", 10, 64);
  pdf.setFont("helvetica", "normal");
  pdf.text(params[0].seller_name, 50, 64);

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(12);
  pdf.text("Payment method:", 10, 71);
  pdf.setFont("helvetica", "normal");
  pdf.text(params[0].payment_method, 50, 71);

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(12);
  pdf.text("Refunded Amount:", 10, 78);
  pdf.setFont("helvetica", "normal");
  pdf.text("$" + params[0].refunded_amount, 50, 78);

  pdf.autoTable({
    head: [["Product Name", "Description", "Item Price", "Shipping Price"]],
    body: tableData,
    startY: 85,
  });

  const lastTable = pdf.lastAutoTable;
  const lastTableHeight = lastTable.finalY - 85;

  let additionalUnits = params[0].items.length - 1;
  let additionalShippingCost = additionalUnits * 2.5;

  const priorityShipping =
    params[0].priorty_mail_shipping === 1
      ? 5.99 + additionalShippingCost
      : "0.00";

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(12);
  pdf.text("Item Cost:", 138, lastTableHeight + 100);
  pdf.setFont("helvetica", "normal");
  pdf.text("$" + itemPrice, 185, lastTableHeight + 100);

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(12);
  pdf.text("Shipping Cost:", 138, lastTableHeight + 107);
  pdf.setFont("helvetica", "normal");
  pdf.text("$" + shippingPrice, 185, lastTableHeight + 107);

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(12);
  pdf.text("Priority Mail Shipping:", 138, lastTableHeight + 114);
  pdf.setFont("helvetica", "normal");
  pdf.text("$" + priorityShipping, 185, lastTableHeight + 114);

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(14);
  pdf.text("Grand Total:", 138, lastTableHeight + 121);
  pdf.text("$" + grandTotal, 185, lastTableHeight + 121);

  const watermarkText = "PAID";
  const fontSize = 80;
  const opacity = 0.1;
  const rotation = 45;

  pdf.setFontSize(fontSize);
  pdf.setTextColor(0, 0, 0, opacity);
  pdf.text(watermarkText, 80, 180, null, rotation);

  pdf.save(`${params[0].order_id.toString()}.pdf`);
};

export const downloadReciept = async (order, dispatch) => {
  dispatch(loadingAction(true));

  const payload = {
    order_id: order.id,
  };

  try {
    const res = await request.post(`/payments/receipt`, payload);

    if (res) {
      const pdfParams = res.data.data;
      formatPDF(pdfParams, order);

      dispatch(loadingAction(false));
      toastify("success", res.data.message);
    }
  } catch (e) {
    // toastify("error", e.response.data.message);
    dispatch(loadingAction(false));
  }
};
