import React from "react";
import request from "src/utils/request";
import StyledButton from "src/components/button/StyledButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { toastify } from "src/utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart } from "src/redux/actions/productActions";
import { loadingBtnAction } from "src/redux/actions/userActions";

const AddToCart = ({ disabled, reset }) => {
  const dispatch = useDispatch();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  const addToCartLoading = useSelector((state) => state.user.loadingButton);
  const productsInCart = useSelector((state) => state.product.productsInCart);
  const graphicURL = useSelector((state) => state.product.graphicURL);
  const notecardURL = useSelector((state) => state.product.notecardURL);

  const uploadedGraphicImage = useSelector(
    (state) => state.product.uploadedGraphicImage
  );
  const uploadedNotecardImage = useSelector(
    (state) => state.product.uploadedNotecardImage
  );
  const type = catalogDetails?.breadCrumb;
  const noOfGraphics =
    type === "products"
      ? catalogDetails?.catalog?.no_of_graphics
      : catalogDetails.no_of_graphics;

  const addToCart = async () => {
    let catalogID =
      type === "products" ? catalogDetails?.catalog?.id : catalogDetails?.id;

    let formData = new FormData();
    formData.append("catalog_id", catalogID);
    formData.append("quantity", "1");

    if (graphicURL) {
      formData.append("url", graphicURL);
    } else {
      formData.append("graphic_image", uploadedGraphicImage?.src);
    }

    if (noOfGraphics === 2) {
      if (notecardURL) {
        formData.append("notecard_url", notecardURL);
      } else {
        formData.append("notecard_image", uploadedNotecardImage?.src);
      }
    }

    dispatch(loadingBtnAction(true));
    try {
      const res = await request.post(`/cart`, formData);

      if (res) {
        if (type === "Catalog") {
          reset();
        }
        dispatch(loadingBtnAction(false));
        toastify("success", res.data.message);
        dispatch(addProductToCart(productsInCart + 1));
      }
    } catch (e) {
      dispatch(loadingBtnAction(false));
      toastify("error", e.response.data.message);
    }
  };

  return (
    <StyledButton
      title={"Add to Cart"}
      disabled={disabled}
      onClick={addToCart}
      loading={addToCartLoading}
      startIcon={<ShoppingCartIcon />}
    />
  );
};

export default AddToCart;
