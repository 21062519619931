import React from "react";
import {
  Avatar,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { copyURLToClipboard } from "src/utils/commonFunctions";
import Iconify from "src/components/iconify/Iconify";
import DeleteContent from "src/sections/modals/education/DeleteContent";
import AddContent from "src/sections/modals/education/add-content/AddContent";

const EducationUrls = ({ item }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12} sm={6} md={8} lg={6}>
        <Card
          sx={{
            marginY: "20px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 26px",
            px: 2,
            height: "386px",
          }}
        >
          <CardHeader
            sx={{ py: 2, px: 0, fontWeight: "bold" }}
            disableTypography={true}
            avatar={
              <Avatar
                sx={{
                  bgcolor: theme.palette.primary.main,
                }}
              >
                <Iconify
                  icon={"bi:link"}
                  width={30}
                  sx={{
                    color: theme.palette.white.main,

                    borderRadus: "16px",
                  }}
                />
              </Avatar>
            }
            action={
              <>
                <IconButton>
                  <a href={item.media} target="_blank" rel="noreferrer">
                    <Iconify
                      icon="mingcute:arrow-right-up-fill"
                      color="primary.main"
                    />
                  </a>
                </IconButton>
              </>
            }
            title={item?.name}
          />
          <a href={item.media} target="_blank" rel="noreferrer">
            <CardMedia
              component="img"
              image={item?.meta_image}
              alt="Cover"
              height={260}
            />
          </a>
          <CardActions
            disableSpacing
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton
              aria-label="edit"
              onClick={() =>
                dispatch(setModalState(<AddContent item={item} edit />))
              }
            >
              <Iconify icon="eva:edit-fill" color="primary.main" />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() =>
                dispatch(setModalState(<DeleteContent id={item.id} />))
              }
            >
              <Iconify icon="eva:trash-2-outline" color="error.main" />
            </IconButton>
            <IconButton onClick={() => copyURLToClipboard(item?.media)}>
              <Iconify icon="tabler:copy" color="primary.main" />
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    </>
  );
};

export default EducationUrls;
