import { toastify } from "./toast";

export const copyURLToClipboard = (urlToCopy) => {
  navigator.clipboard
    .writeText(urlToCopy)
    .then(() => {
      toastify("success", "URL copied to clipboard");
    })
    .catch((err) => {
      console.error("Failed to copy URL: ", err);
    });
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export function hasNull(arr) {
  return arr.some((value) => value === null);
}


export function bytesToMB(bytes) {
  return (bytes / (1024 * 1024)).toFixed(3);
}