import box from "../../assets/icons/box.svg";
import pending from "../../assets/icons/pending.svg";
import approved from "../../assets/icons/approved.svg";
import delivery from "../../assets/icons/delivery.svg";

export const getWidgetsArray = (userType, scopes, apps, orderStats) => {
  if (userType === "Developer") {
    return [
      {
        title: "Total Scopes",
        total: scopes ? parseFloat(scopes.length) : 0,
        cardColor: "card_one",
        iconColor: "icon_one",
        icon: box,
      },
      {
        title: "Total Applications",
        total: apps ? parseFloat(apps.length) : 0,
        cardColor: "card_one",
        iconColor: "icon_one",
        icon: box,
      },
    ];
  } else {
    return [
      {
        title: "Total Revenue",
        total: orderStats ? parseFloat(orderStats.revenue) : 0,
        cardColor: "card_one",
        iconColor: "icon_one",
        icon: box,
      },
      {
        title:
          userType === "Seller" ? "On Hold Orders" : "Awaiting Approval Orders",
        total: orderStats
          ? userType === "Seller"
            ? orderStats.on_hold
            : orderStats.awaiting_approval
          : 0,
        cardColor: "card_one",
        iconColor: "icon_one",
        icon: pending,
      },
      {
        title:
          userType === "Seller"
            ? "In Production Orders"
            : "Awaiting Shipment Orders",
        total: orderStats
          ? userType === "Seller"
            ? orderStats.in_production
            : orderStats.awaiting_shipment
          : 0,
        cardColor: "card_one",
        iconColor: "icon_one",
        icon: delivery,
      },
      {
        title: userType === "Seller" ? "Fulfilled Orders" : "Shipped Orders",
        total: orderStats ? orderStats.shipped : 0,
        cardColor: "card_one",
        iconColor: "icon_one",
        icon: approved,
      },
    ];
  }
};
