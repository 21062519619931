import React from "react";
import { useDispatch } from "react-redux";
import StyledButton from "src/components/button/StyledButton";
import Iconify from "src/components/iconify";
import { setModalState } from "src/redux/actions/modalActions";
import LinkAmazonProduct from "src/sections/modals/products/LinkAmazonProduct";
import { unLinkProduct } from "../utils";

const LinkToAmazon = ({ amazonSKU, id }) => {
  const dispatch = useDispatch();
  return (
    <StyledButton
      title={amazonSKU === null ? "Link To Amazon" : "Unlink Amazon product"}
      sx={{
        bgcolor: "#343b45",
        "&:hover": {
          bgcolor: "#343b45",
        },
      }}
      startIcon={<Iconify icon="bi:amazon" />}
      onClick={() => {
        if (amazonSKU === null) {
          dispatch(
            setModalState(
              <LinkAmazonProduct id={id} page={1} rowsPerPage={25} />
            )
          );
        } else {
          unLinkProduct(dispatch, id, "amazon", 1, 25);
        }
      }}
    />
  );
};

export default LinkToAmazon;
