import React from "react";
import Cart from "./Cart";
import ShippingForm from "./shipping/ShippingForm";
import { useSelector } from "react-redux";
import { Card, Container, Stack, Typography } from "@mui/material";

const Checkout = () => {
  const activeStep = useSelector((state) => state.user.activeStep);

  return (
    <Container maxWidth="2xl" sx={{ margin: "0px", padding: "0px" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Checkout
        </Typography>
      </Stack>

      <Card sx={{ padding: "20px 0px" }}>
        {activeStep === 0 ? <Cart /> : activeStep === 1 ? <ShippingForm /> : ""}
      </Card>
    </Container>
  );
};

export default Checkout;
