import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Button, Drawer } from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
import navConfig from "./config";
import { useSelector } from "react-redux";
import Iconify from "src/components/iconify/Iconify";

const NAV_WIDTH = 280;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const userType = useSelector((state) => state.user.user.type);

  const NavRoutes =
    userType === "Seller"
      ? navConfig.filter(
          (_, index) => index !== 2 && index !== 6 && index !== 7 && index !== 9
        )
      : userType === "Developer"
      ? navConfig.filter(
          (_, index) => index === 0 || index === 6 || index === 7
        )
      : userType === "AdminAccessGuard"
      ? navConfig.filter((_, index) => index === 2)
      : userType === "PrintProvider"
      ? navConfig.filter((_, index) => index === 1 || index === 9)
      : navConfig.filter(
          (_, index) => index !== 3 && index !== 5 && index !== 7 && index !== 9
        );

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const StyledContainer = styled("div")({
    position: "absolute",
    bottom: 30,
    left: 30,
    zIndex: 996,
  });

  const StyledButton = styled(Button)({
    backgroundColor: "#02B2FE",
    outline: "none",
    border: "transparent",
    color: "#fff",
  });

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <NavSection data={NavRoutes} />
      {userType === "Seller" && (
        <StyledContainer>
          <a
            href="https://innercircleprints.slack.com"
            target="_blank"
            rel="noreferrer"
          >
            <StyledButton
              variant="contained"
              startIcon={<Iconify icon={"mdi:chat-processing-outline"} />}
            >
              Get Support
            </StyledButton>
          </a>
        </StyledContainer>
      )}
    </Scrollbar>
  );

  return (
    <Box
      className="testing-wrapper"
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          className="Drawer-test-1"
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "white",
              margin: "10px 10px",
              borderRadius: 3,
              height: "70vh",
              borderRightStyle: "dashed",
              position: "relative",
              zIndex: 0,
              boxShadow: "5px -1px 5px 0px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          className="Drawer-test-2"
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
