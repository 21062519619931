import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { setModalState } from "src/redux/actions/modalActions";
import {
  loadingAction,
  loadingBtn2Action,
  loadingBtnAction,
} from "src/redux/actions/userActions";
import {
  setEducationContent,
  setEducationSections,
  setShowSectionInput,
} from "src/redux/actions/educationActions";

export const getContent = async (dispatch) => {
  dispatch(loadingAction(true));

  try {
    const res = await request.get("/educationalcontent");
    if (res) {
      dispatch(loadingAction(false));
      dispatch(setEducationContent(res.data.data));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingAction(false));
  }
};

export const addEducationContent = async (dispatch, formatData) => {
  dispatch(loadingBtn2Action(true));
  try {
    const res = await request.post("/educationalcontent", formatData);

    if (res) {
      dispatch(setModalState(undefined));
      dispatch(loadingBtn2Action(false));
      toastify("success", res.data.message);
      getContent(dispatch);
    }
  } catch (e) {
    dispatch(loadingBtn2Action(false));
    toastify("error", e.response.data.message);
  }
};

export const getEducationSection = async (dispatch) => {
  try {
    const res = await request.get("/educationalcontent/sections");
    if (res) {
      dispatch(setEducationSections(res.data.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      dispatch(setEducationSections([]));
    } else {
      toastify("error", e.response.data.message);
    }
  }
};

export const addEducationSection = async (dispatch, payload) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post("/educationalcontent/section", payload);

    if (res) {
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      getEducationSection(dispatch);
      dispatch(setShowSectionInput(false));
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const updateSection = async (dispatch, payload) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.patch("/educationalcontent/section", payload);

    if (res) {
      dispatch(loadingBtnAction(false));
      dispatch(setModalState(undefined));
      toastify("success", res.data.message);
      getContent(dispatch);
      dispatch(setShowSectionInput(false));
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const deleteSection = async (dispatch, id) => {
  dispatch(loadingBtnAction(true));

  try {
    const res = await request.delete(`/educationalcontent/section/${id}`);
    if (res) {
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      dispatch(setModalState(undefined));
      getContent(dispatch);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const updateContent = async (dispatch, payload) => {
  dispatch(loadingBtn2Action(true));
  try {
    const res = await request.post("/educationalcontent/update", payload);

    if (res) {
      dispatch(loadingBtn2Action(false));
      dispatch(setModalState(undefined));
      toastify("success", res.data.message);
      getContent(dispatch);
      dispatch(setShowSectionInput(false));
    }
  } catch (e) {
    dispatch(loadingBtn2Action(false));
    toastify("error", e.response.data.message);
  }
};

export const deleteContent = async (dispatch, id) => {
  dispatch(loadingBtnAction(true));

  try {
    const res = await request.delete(`/educationalcontent/${id}`);
    if (res) {
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      dispatch(setModalState(undefined));
      getContent(dispatch);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};
