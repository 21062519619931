import { setOrderStats } from "src/redux/actions/orderActions";
import { loadingAction } from "src/redux/actions/userActions";
import request from "src/utils/request";

export const getOrderStats = async (dispatch, userType) => {
  dispatch(loadingAction(true));
  try {
    const res = await request.get(
      userType === "Seller"
        ? "/orders/stats/seller"
        : userType === "PrintProvider"
        ? "/print-provider/orders/stats/printprovider"
        : "/orders/stats/admin"
    );
    if (res) {
      const data = res?.data?.data?.stats;
      dispatch(setOrderStats(data));
      dispatch(loadingAction(false));
    }
  } catch (e) {
    dispatch(loadingAction(false));
  }
};
