import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { productData } from "./productStatsConfig";
import { AppWidgetSummary } from "../../sections/dashboard/app";

const AllUserStats = () => {
  const allUserStats = useSelector((state) => state.user.allUserStats);

  return (
    <Grid container spacing={3} mb={3}>
      {productData.map((product, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <AppWidgetSummary
            sx={{ height: "100%" }}
            title={product.title}
            total={allUserStats ? product.total(allUserStats) : 0}
            card_color="card_one"
            icon_color="icon_one"
            icon={product.icon}
            stats
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default AllUserStats;
