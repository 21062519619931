import React from "react";
import Logo from "../../assets/img/innerlogo.png";
import { Paper } from "@mui/material";
import FooterContent from "src/sections/footer";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 footer-contact">
              <div className="d-flex justify-content-center">
                <img src={Logo} alt="logo" />
              </div>

              <p>10807 E Montgomery Drive Spokane Valley, WA 99206</p>
            </div>
          </div>
        </div>
      </div>

      <Paper component="footer" square variant="outlined" elevation={3}>
        <FooterContent />
      </Paper>
    </footer>
  );
};

export default Footer;
