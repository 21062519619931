import request from "src/utils/request";
import { setModalState } from "src/redux/actions/modalActions";
import {
  loadingAction,
  loadingBtnAction,
  setAllUserStats,
  setPrintProviders,
  setUserList,
  setUserSearched,
  setUsersMeta,
} from "src/redux/actions/userActions";
import { toastify } from "src/utils/toast";

export const getUsers = async (dispatch, page, rowsPerPage, selectedFilter) => {
  if (selectedFilter === "Print Providers") {
    getPrintProviders(dispatch, page, rowsPerPage);
  } else {
    getAllUsers(dispatch, page, rowsPerPage);
  }
};

const getAllUsers = async (dispatch, page, rowsPerPage) => {
  dispatch(loadingAction(true));
  try {
    const res = await request.get(
      `/users?page=${page}&&rowsPerPage=${rowsPerPage}`
    );

    if (res) {
      dispatch(setUserList(res.data.data.users.data));
      dispatch(
        setUsersMeta(
          res.data.data.ActiveUsers,
          res.data.data.InactiveUsers,
          res.data.data.users.meta,
          0
        )
      );
      dispatch(setUserSearched(false));
      dispatch(loadingAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingAction(false));
  }
};

export const getPrintProviders = async (dispatch, page, rowsPerPage) => {
  dispatch(loadingAction(true));
  try {
    const res = await request.get(
      `/print-providers?page=${page}&&rowsPerPage=${rowsPerPage}`
    );

    if (res) {
      dispatch(setUserList(res.data.data.users.data));
      dispatch(setPrintProviders(res.data.data.users.data));
      dispatch(
        setUsersMeta(
          res.data.data.ActiveUsers,
          res.data.data.InactiveUsers,
          res.data.data.users.meta,
          0
        )
      );
      dispatch(setUserSearched(false));
      dispatch(loadingAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingAction(false));
  }
};

export const getAllUserStats = async (dispatch) => {
  dispatch(loadingAction(true));
  try {
    const res = await request.get("/users/stats/all");

    if (res) {
      dispatch(setAllUserStats(res.data.data));
      dispatch(loadingAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingAction(false));
  }
};

export const onDeleteUser = async (dispatch, userID, selectedFilter) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.delete(`/users/${userID}`);

    if (res) {
      dispatch(setModalState(undefined));
      toastify("success", res.data.message);
      getUsers(dispatch, 1, 25, selectedFilter);
      dispatch(loadingBtnAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};

export const changeStatus = async (dispatch, userID, selectedFilter) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.get(`/users/status/${userID}`);

    if (res) {
      dispatch(setModalState(undefined));
      toastify("success", res.data.message);
      getUsers(dispatch, 1, 25, selectedFilter);
      dispatch(loadingBtnAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};

export const searchUser = async (dispatch, searchValue) => {
  dispatch(loadingAction(true));

  try {
    const res = await request.get(`/users/search/${searchValue}`);

    if (res) {
      dispatch(setUserList(res.data.data.users));
      dispatch(setUsersMeta(0, 0, 0, res.data.data.count));
      dispatch(setUserSearched(true));
      dispatch(loadingAction(false));
      toastify("success", res.data.message);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingAction(false));
  }
};
