import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { setModalState } from "src/redux/actions/modalActions";
import { setUpdates } from "src/redux/actions/educationActions";
import { loadingAction, loadingBtnAction } from "src/redux/actions/userActions";

export const getPosts = async (dispatch) => {
  dispatch(loadingAction(true));

  try {
    const res = await request.get(`/post`);
    if (res) {
      dispatch(setUpdates(res.data.data));
      dispatch(loadingAction(false));
    }
  } catch (e) {
    dispatch(loadingAction(false));
  }
};

export const submitPost = async (dispatch, formData) => {
  dispatch(loadingBtnAction(true));

  try {
    const res = await request.post(`/post`, formData);
    if (res) {
      dispatch(setModalState(undefined));
      getPosts(dispatch);
      dispatch(loadingBtnAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};

export const editPost = async (postId, dispatch, formData) => {
  dispatch(loadingBtnAction(true));

  try {
    const res = await request.post(`/post/${postId}`, formData);
    if (res) {
      dispatch(setModalState(undefined));
      getPosts(dispatch);
      dispatch(loadingBtnAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};

export const deletePost = async (postId, dispatch) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.delete(`/post/${postId}`);
    if (res) {
      dispatch(setModalState(undefined));
      getPosts(dispatch);
      dispatch(loadingBtnAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};
