import React, { useState } from "react";
import { sentenceCase } from "change-case";
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Button,
  Tooltip,
} from "@mui/material";
import Iconify from "../../components/iconify";
import Scrollbar from "../../components/scrollbar";
import EditUser from "src/sections/modals/user/editUser";
import AddCredit from "src/sections/modals/user/AddCredit";
import UserStats from "src/sections/modals/user/userStats";
import UserStatus from "src/sections/modals/user/userStatus";
import DeleteUsers from "src/sections/modals/user/deleteUsers";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { changeStatus, getUsers, onDeleteUser } from "./requests";
import { setUserPagination } from "src/redux/actions/userActions";
import { applySortFilter, getComparator, TABLE_HEAD } from "./utils";
import { UserListHead, UserListToolbar } from "../../sections/dashboard/user";

const UserTable = ({ userList, userStatus }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(null);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [showUserStats, setShowUserStats] = useState([]);
  const searched = useSelector((state) => state.user.searched);
  const usersMeta = useSelector((state) => state.user.userMeta);
  const userPagination = useSelector((state) => state.user.userPagination);
  const selectedFilter = useSelector((state) => state.user.selectedFilter);

  const page = userPagination ? userPagination?.page : 1;
  const rowsPerPage = userPagination ? userPagination?.rowsPerPage : 25;

  const USERLIST = userList
    ? userList?.map((user, index) => ({
        id: user.id,
        avatarUrl:
          index > 23
            ? "/assets/images/avatars/avatar_default.jpg"
            : `/assets/images/avatars/avatar_${index + 1}.jpg`,
        name: user.name,
        email: user.email,
        status: user.status ? "Active" : "Blocked",
        role: user.user_type,
      }))
    : [];

  const handleOpenMenu = (event, id, name, email) => {
    setUser({ id: id, name: name, email: email });
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setUserPagination(newPage + 1, rowsPerPage));
    getUsers(dispatch, newPage + 1, rowsPerPage, selectedFilter);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setUserPagination(page, parseInt(event.target.value, 10)));
    getUsers(dispatch, page, parseInt(event.target.value, 10), selectedFilter);
  };

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy)
  );

  return (
    <>
      {!isEmpty(showUserStats) ? (
        <UserStats
          row={showUserStats}
          setShowUserStats={setShowUserStats}
          avatarUrl={showUserStats.avatarUrl}
        />
      ) : (
        <>
          <Card>
            <UserListToolbar
              placeholder={"Search user..."}
              numSelected={selected.length}
              user
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />

                  <TableBody>
                    {filteredUsers?.length ? (
                      filteredUsers?.map((row, index) => {
                        const { id, name, role, status, email, avatarUrl } =
                          row;

                        return (
                          <TableRow hover key={index} tabIndex={-1}>
                            <TableCell padding="checkbox"></TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar alt={name} src={avatarUrl} />
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">{email}</TableCell>

                            <TableCell align="left">{role}</TableCell>

                            <TableCell align="left">
                              <Button
                                variant="contained"
                                style={{
                                  color:
                                    status === "Active" ? "#229A16" : "#b72136",
                                  backgroundColor:
                                    status === "Active"
                                      ? "rgba(84, 214, 44, 0.16)"
                                      : "rgba(255, 72, 66, 0.16)",
                                }}
                                onClick={() =>
                                  dispatch(
                                    setModalState(
                                      <UserStatus
                                        status={status}
                                        changeStatus={() =>
                                          changeStatus(
                                            dispatch,
                                            id,
                                            selectedFilter
                                          )
                                        }
                                      />
                                    )
                                  )
                                }
                              >
                                {sentenceCase(status)}
                              </Button>
                            </TableCell>

                            <TableCell align="right">
                              <Tooltip title="User Stats" arrow>
                                <Iconify
                                  icon={"ic:baseline-remove-red-eye"}
                                  sx={{ mr: 2, cursor: "pointer" }}
                                  onClick={() => setShowUserStats(row)}
                                />
                              </Tooltip>
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(e) =>
                                  handleOpenMenu(e, id, name, email)
                                }
                              >
                                <Iconify icon={"eva:more-vertical-fill"} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              No {userStatus} users found on this page
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={searched ? usersMeta?.count : usersMeta?.meta?.total}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                dispatch(setModalState(<AddCredit userID={user?.id} />));
              }}
            >
              <Iconify icon={"material-symbols:wallet"} sx={{ mr: 2 }} />
              Add Credit
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleCloseMenu();
                dispatch(setModalState(<EditUser updateUser={user} />));
              }}
            >
              <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
              Edit
            </MenuItem>

            <MenuItem
              sx={{ color: "error.main" }}
              onClick={() => {
                handleCloseMenu();
                dispatch(
                  setModalState(
                    <DeleteUsers
                      deleteUser={() =>
                        onDeleteUser(dispatch, user?.id, selectedFilter)
                      }
                    />
                  )
                );
              }}
            >
              <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </Popover>
        </>
      )}
    </>
  );
};

export default UserTable;
