import React, { useEffect } from "react";
import { Paper, styled, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { useDropzone } from "react-dropzone";
import { bytesToMB } from "src/utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import LibraryImg from "../../../assets/images/library.png";
import { setTaxDocument } from "src/redux/actions/userActions";
import { setModalState } from "src/redux/actions/modalActions";
import TaxDocument from "src/sections/modals/taxDocument/TaxDocument";
import { getTaxDocument, handleDelete, uploadDocument } from "./request";
import DeleteTaxDocument from "src/sections/modals/taxDocument/DeleteDocument";
import ListDocument from "src/components/list-items/ListDocument";

const StyledDropzone = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.grey[200],
  textAlign: "center",
  cursor: "pointer",
}));

const UploadTaxDocument = () => {
  const dispatch = useDispatch();
  const taxDocument = useSelector((state) => state.user.taxDocument);

  useEffect(() => {
    getTaxDocument(dispatch);

    dispatch(setTaxDocument([]));
  }, [dispatch]);

  const onDrop = (acceptedFiles) => {
    uploadDocument(dispatch, acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div>
      {!isEmpty(taxDocument) ? (
        <>
          <ListDocument
            sx={{ width: "50%" }}
            title={"Resellers Tax Permit"}
            desc={`${bytesToMB(taxDocument[0]?.file_size)} MB
                    - ${format(
                      new Date(taxDocument[0]?.created_at),
                      "dd MMM yyyy h:mm a"
                    )}
                    `}
            onDelete={() =>
              dispatch(
                setModalState(
                  <DeleteTaxDocument
                    handleDelete={() =>
                      handleDelete(dispatch, taxDocument[0].id)
                    }
                  />
                )
              )
            }
            onClickItem={() =>
              dispatch(
                setModalState(
                  <TaxDocument fileURL={taxDocument[0]?.document} />,
                  true
                )
              )
            }
          />
        </>
      ) : (
        <StyledDropzone
          elevation={3}
          {...getRootProps()}
          sx={{ height: "30vh" }}
        >
          <input {...getInputProps()} multiple />
          <img src={LibraryImg} alt="library" style={{ width: 120 }} />
          <div>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Drag Your Document Here
            </Typography>
          </div>
        </StyledDropzone>
      )}
    </div>
  );
};

export default UploadTaxDocument;
