import React from "react";
import { TextField } from "@mui/material";

const EditCatalogTitle = ({ productTitle, onChange }) => {
  return (
    <TextField
      label="Product Title"
      variant="outlined"
      type="text"
      value={productTitle}
      onChange={onChange}
      sx={{ width: "100%", my: 2 }}
    />
  );
};

export default EditCatalogTitle;
