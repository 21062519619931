import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  TextField,
  styled,
} from "@mui/material";
import AddImage from "./AddImage";
import AddVideo from "./AddVideo";
import AddDocument from "./AddDocument";
import Typography from "@mui/material/Typography";
import { setModalState } from "src/redux/actions/modalActions";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { editPost, submitPost } from "src/pages/dashboard/requests";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const StyledDropzone = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.grey[200],
  textAlign: "center",
  cursor: "pointer",
}));

const fullToolbarOptions = [
  [{ font: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ script: "sub" }, { script: "super" }],
  ["link", "image", "video"],
  ["clean"],
];

const CreateUpdate = ({ item }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loadingButton);
  const [editorValue, setEditorValue] = useState(item ? item?.content : "");
  const [images, setImages] = useState(
    item && item?.images ? [item?.images] : []
  );
  const [videos, setVideos] = useState(
    item && item?.videos ? [item?.videos] : []
  );
  const [documents, setDocuments] = useState(
    item && item?.document ? [item?.document] : []
  );
  const [url, setURL] = useState(item && item?.links ? item?.links : "");

  const handleURL = (event) => {
    setURL(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (images.length) {
      for (let i = 0; i < images.length; i++) {
        formData.append("image", images[i]);
      }
    }

    if (videos.length) {
      for (let i = 0; i < videos.length; i++) {
        formData.append("video", videos[i]);
      }
    }

    if (documents.length) {
      for (let i = 0; i < documents.length; i++) {
        formData.append("document", documents[i]);
      }
    }

    if (url) {
      formData.append("link", url);
    }

    formData.append("content", editorValue);

    if (item) {
      editPost(item.id, dispatch, formData);
    } else {
      submitPost(dispatch, formData);
    }
  };

  return (
    <Box sx={{ padding: "12px" }}>
      <Typography variant="h5" sx={{ mb: 1.5 }}>
        Add an Update
      </Typography>

      <ReactQuill
        theme="snow"
        placeholder="What's the update"
        modules={{ toolbar: fullToolbarOptions }}
        value={editorValue}
        onChange={setEditorValue}
        // style={{ height: "150px" }}
      />

      <Typography variant="h5" sx={{ mt: 2 }}>
        Add Link
      </Typography>

      <TextField
        label="URL"
        variant="outlined"
        value={url}
        onChange={handleURL}
        fullWidth
        sx={{ my: 2 }}
      />

      <AddImage images={images} setImages={setImages} />

      <AddVideo videos={videos} setVideos={setVideos} />

      <AddDocument documents={documents} setDocuments={setDocuments} />

      <Stack direction={"row"} justifyContent={"center"} spacing={3} mt={8}>
        <Button
          variant="contained"
          color="inherit"
          fullWidth
          onClick={() => dispatch(setModalState(undefined))}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          loading={loading}
          loadingIndicator={<CircularProgress size={12} color="inherit" />}
        >
          Confirm
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default CreateUpdate;
