import request from "src/utils/request";
import {
  loadingAction,
  loadingBtnAction,
  setNotificationIsSeen,
  setTaxDocument,
} from "src/redux/actions/userActions";
import { setModalState } from "src/redux/actions/modalActions";
import { toastify } from "src/utils/toast";

export const getTaxDocument = async (dispatch) => {
  dispatch(loadingAction(true));

  try {
    const res = await request.get("/documents");

    if (res) {
      dispatch(loadingAction(false));
      dispatch(setTaxDocument(res?.data?.data));
      dispatch(setNotificationIsSeen(true));
    }
  } catch (e) {
    dispatch(loadingAction(false));
    dispatch(setTaxDocument([]));
  }
};

export const getTaxDocumentAdmin = async (dispatch, id) => {
  dispatch(loadingAction(true));

  try {
    const res = await request.get(`/documents/${id}`);

    if (res) {
      dispatch(loadingAction(false));
      dispatch(setTaxDocument(res?.data?.data));
    }
  } catch (e) {
    dispatch(loadingAction(false));
    dispatch(setTaxDocument([]));
  }
};

export const uploadDocument = async (dispatch, file) => {
  let formData = new FormData();
  formData.append("document", file[0]);
  dispatch(loadingAction(true));
  try {
    const res = await request.post("/documents", formData);
    if (res) {
      getTaxDocument(dispatch);
      toastify("success", res.data.message);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
  }
};

export const handleDelete = async (dispatch, id) => {
  dispatch(loadingBtnAction(true));

  try {
    const res = await request.delete(`/documents/${id}`);

    if (res) {
      getTaxDocument(dispatch);
      dispatch(setModalState(undefined));
      dispatch(loadingBtnAction(false));
      dispatch(setTaxDocument([]));
      toastify("success", res.data.message);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};
