export const productAttributes = [
  {
    id: 47,
    name: "gerber cotton",
    needsColor: false,
    needsSize: true,
    no_of_graphics: 1,
    sizes: [
      { label: "0-3 Months", value: "0-3 Months" },
      { label: "3-6 Months", value: "3-6 Months" },
      { label: "6-9 Months", value: "6-9 Months" },
      { label: "12 Months", value: "12 Months" },
      { label: "18 Months", value: "18 Months" },
    ],
  },
  {
    id: 48,
    name: "organic toddler",
    needsColor: false,
    needsSize: true,
    no_of_graphics: 1,
    sizes: [
      { label: "2T", value: "2T" },
      { label: "3T", value: "3T" },
      { label: "4T", value: "4T" },
      { label: "5T", value: "5T" },
    ],
  },
  {
    id: 49,
    name: "organic youth",
    needsColor: false,
    needsSize: true,
    no_of_graphics: 1,
    sizes: [
      { label: "6-8 Youth", value: "6-8 Youth" },
      { label: "10-12 Youth", value: "10-12 Youth" },
      { label: "14-16 Youth", value: "14-16 Youth" },
    ],
  },
  {
    id: 27,
    name: "necklace",
    needsColor: true,
    needsSize: true,
    no_of_graphics: 2,
    sizes: [
      { label: "16“", value: "16“" },
      { label: "18“", value: "18“" },
      { label: "20“", value: "20“" },
      { label: "24“", value: "24“" },
    ],
  },
  {
    id: 38,
    name: "bracelet",
    needsColor: true,
    needsSize: true,
    no_of_graphics: 2,
    sizes: [
      { label: "60 MM (Standard)", value: "60 MM (Standard)" },
      { label: "65 MM (Large)", value: "65 MM (Large)" },
    ],
  },
  {
    id: 39,
    name: "keychain",
    needsColor: true,
    needsSize: false,
    no_of_graphics: 2,
    sizes: null,
  },
  {
    id: 45,
    name: "Initials",
    needsColor: true,
    needsSize: false,
    no_of_graphics: 1,
    sizes: null,
  },
];

export function getDefaultArray(item) {
  // Initialize an empty array for variations
  let defaultArray = [];

  // Personalization object is mandatory
  defaultArray.push({
    property_id: 54,
    value_id: 3,
    formatted_name: "Personalization",
    formatted_value: "",
  });

  // Check if size is needed and push the size object if applicable
  if (item?.needsSize && item?.sizes && item?.sizes?.length > 0) {
    defaultArray.push({
      property_id: 506,
      value_id: 2,
      formatted_name: "Size",
      formatted_value: item.sizes[0].value, // Default to the first size value
    });
  }

  // Check if color is needed and push the color object if applicable
  if (item?.needsColor) {
    defaultArray.push({
      property_id: 500,
      value_id: 1,
      formatted_name: "Color",
      formatted_value: "Gold", // Default color value
    });
  }

  // Check if no_of_graphics is 2 and add birthstones if applicable
  if (item?.no_of_graphics === 2) {
    defaultArray.push({
      property_id: 504,
      value_id: 4,
      formatted_name: "Birthstones",
      formatted_value: [], // Can be filled later
    });
  }

  return defaultArray;
}

export function fillFormattedValues(
  defaultArray,
  color,
  size,
  personalization,
  birthstone
) {
  defaultArray.forEach((variation) => {
    if (variation.formatted_name === "Color") {
      variation.formatted_value = color;
    }

    if (variation.formatted_name === "Size") {
      variation.formatted_value = size;
    }

    if (variation.formatted_name === "Personalization") {
      variation.formatted_value = personalization;
    }

    if (variation.formatted_name === "Birthstones") {
      variation.formatted_value = birthstone;
    }
  });

  return defaultArray;
}
