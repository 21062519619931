import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateSection } from "src/pages/education/request";
import { setModalState } from "src/redux/actions/modalActions";

const UpdateEducationSection = ({ id, title }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loadingButton);

  const handleSubmit = (e) => {
    e.preventDefault();
    const sectionName = e.target.elements.title.value;

    const payload = {
      section_id: id,
      name: sectionName,
    };
    updateSection(dispatch, payload);
  };

  return (
    <Box sx={{ padding: "12px" }}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h5" sx={{ mb: 1.5 }}>
          Section Name
        </Typography>

        <TextField
          label="Name"
          name="title"
          variant="outlined"
          defaultValue={title}
          fullWidth
        />
        <Box sx={{ my: 4 }}></Box>
        <Stack direction={"row"} justifyContent={"center"} spacing={3}>
          <Button
            variant="contained"
            color="inherit"
            fullWidth
            onClick={() => dispatch(setModalState(undefined))}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            loading={loading}
            loadingIndicator={<CircularProgress size={12} color="inherit" />}
          >
            Update
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default UpdateEducationSection;
