import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { loadingBtnAction } from "src/redux/actions/userActions";
import { setModalState } from "src/redux/actions/modalActions";

export const getNotes = async (userID, setNotes, ) => {
  try {
    const res = await request.get(`/users/meeting/notes/${userID}`);

    if (res) {
      setNotes(res.data.data);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
  }
};

export const addNotes = async (dispatch, data, setNotes, userID) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post(`/users/meeting/notes`, data);

    if (res) {
      dispatch(setModalState(undefined));
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      getNotes(userID, setNotes);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const onDeleteNote = async (dispatch, noteId, setNotes, userID) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.delete(`/users/meeting/notes/${noteId}`);

    if (res) {
      dispatch(setModalState(undefined));
      toastify("success", res.data.message);
      getNotes(userID, setNotes);
      dispatch(loadingBtnAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};

export const updateNotes = async (dispatch, data, setNotes, userID) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.patch(`/users/meeting/notes`, data);

    if (res) {
      dispatch(setModalState(undefined));
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      getNotes(userID, setNotes);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};
