import React from "react";
import { Avatar, IconButton, Stack, Typography, useTheme } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";

const EducationDocs = ({ item, index }) => {
  const theme = useTheme();

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ my: 2 }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={2}
        // sx={{ width: "200px" }}
      >
        <Avatar
          sx={{
            bgcolor: theme.palette.primary.main,
          }}
        >
          <Iconify
            icon="teenyicons:text-document-solid"
            width={20}
            sx={{
              color: theme.palette.white.main,
              borderRadus: "16px",
            }}
          />
        </Avatar>

        <Typography variant="body1">
          <a href={item.media} target="_blank" rel="noreferrer">
            {item?.name}.{item?.type}
          </a>
        </Typography>
      </Stack>
      <IconButton
        aria-label="download"
        onClick={() => {
          window.open(item?.media, "_blank");
        }}
      >
        <Iconify icon="material-symbols:download" color="primary.main" />
      </IconButton>
    </Stack>
  );
};

export default EducationDocs;
