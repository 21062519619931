export const setEducationContent = (content) => async (dispatch) => {
  dispatch({
    type: "setEducationContent",
    payload: content,
  });
};

export const setEducationSections = (sections) => async (dispatch) => {
  dispatch({
    type: "setEducationSections",
    payload: sections,
  });
};

export const setShowSectionInput = (boolean) => async (dispatch) => {
  dispatch({
    type: "setShowSectionInput",
    payload: boolean,
  });
};

export const setUpdates = (data) => async (dispatch) => {
  dispatch({
    type: "setUpdates",
    payload: data,
  });
};
