import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { getProductList } from "../request";
import axios from "axios";
import {
  setPreviewImageSRC,
  setUploadedGraphicImage,
  setUploadedNotecardImage,
} from "src/redux/actions/productActions";

export const unLinkProduct = async (dispatch, id, type, page, rowsPerPage) => {
  const formData = new FormData();
  formData.append("product_id", id);

  try {
    const res = await request.post(`/products/unlink/${type}`, formData);

    if (res) {
      toastify("success", res.data.message);
      getProductList(dispatch, page + 1, rowsPerPage);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
  }
};

export function isValidURL(url) {
  if (url.trim() === "") {
    return true;
  }

  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );

  if (!pattern.test(url)) {
    return false;
  }

  const allowedDomains = ["canva", "kittl", "adobe"];
  const urlDomainPattern = new RegExp(allowedDomains.join("|"), "i");

  return urlDomainPattern.test(url);
}

export function isStringOrFilePath(input) {
  const filePathPattern = /^(\/?|[a-zA-Z]:\\)(?:[^\\/:*?"<>|\r\n]+\\?)*$/;

  if (typeof input === "string") {
    if (filePathPattern.test(input)) {
      return "File Path";
    } else {
      return "String";
    }
  } else {
    return "Unknown";
  }
}

export function isImageUrl(url) {
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|webp|tiff|ico)$/i;

  if (imageExtensions.test(url)) {
    return "image";
  }

  const knownDesignPlatforms = ["canva.com", "kittl.com", "adobe.com"];

  for (let platform of knownDesignPlatforms) {
    if (url.includes(platform)) {
      return "url";
    }
  }

  // If it doesn't match image or design platform, return 'unknown'
  return "unknown";
}

const urlToFile = async (url, filename, mimeType) => {
  const response = await axios.get(url, { responseType: "blob" });
  const blob = new Blob([response.data], { type: mimeType });
  return new File([blob], filename, { type: mimeType });
};

export const handleConvert = async (dispatch, uploadedData, type) => {
  if (uploadedData) {
    try {
      const convertedFile = await urlToFile(
        uploadedData,
        "downloadedFile.png",
        "image/png"
      );
      if (type === "graphic") {
        dispatch(
          setUploadedGraphicImage(convertedFile, "uploaded-graphic.png")
        );
      } else if (type === "notecard") {
        dispatch(
          setUploadedNotecardImage(convertedFile, "uploaded-notecard.png")
        );
      } else if (type === "preview") {
        dispatch(setPreviewImageSRC(convertedFile, "preview-image.png"));
      }
    } catch (error) {
      console.error("Error converting URL to file:", error);
    }
  }
};
