import { React, useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Alert,
  CircularProgress,
  Typography,
  FormControl,
} from "@mui/material";
import { schema } from "./schema";
import { useForm } from "react-hook-form";
import { toastify } from "src/utils/toast";
import { useDropzone } from "react-dropzone";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { StyledDropzone } from "../updates/CreateUpdate";
import { addCatalog, updateCatalog } from "src/pages/catalog/request";
import { StyledLoadingButton } from "src/pages/auth/login/Login.styles";
import Select from "react-select";
import LibraryImg from "../../../assets/images/library.png";
import DeleteImageViewer from "src/components/image-viewer/DeleteImageViewer";

const styles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "#212B36" : "rgba(145, 158, 171, 0.32)",
    height: "55px",
    borderRadius: "6px",
    zIndex: 9999,
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    maxHeight: "180px",
  }),
  menu: (base) => ({ ...base, zIndex: 9999 }),
};

export default function AddCatalogForm({ buttonTitle, product }) {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectPrintprovider, setSelectPrintprovider] = useState([]);

  const userType = useSelector((state) => state.user.user.type);
  const loading = useSelector((state) => state.user.loadingButton);
  const printProviders = useSelector((state) => state.user.printProviders);

  function mapUserData(data) {
    return data.map((item) => ({
      id: item.id,
      label: item.name,
      value: item.uuid,
    }));
  }

  async function createFile() {
    let response = await fetch(product.cover);
    let data = await response.blob();
    let metadata = {
      type: "image/jpeg",
    };
    let file = new File([data], "file.jpg", metadata);
    setImages([file]);
  }

  useEffect(() => {
    if (product) {
      createFile();
    }
  }, [product]);

  useEffect(() => {
    if (printProviders) {
      let options = mapUserData(printProviders);
      setOptions(options);
    }
  }, [printProviders]);

  const onDrop = (acceptedFiles) => {
    setImages(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: product ? product.name : "",
      description: product ? product.desc : "",
      noOfGraphics: product ? product.no_of_graphics : "",
      price: product ? product.price : "",
      comparePrice: product ? product.comparPrice : "",
      shipPrice: product ? product.shipPrice : "",
      sku: product ? product.sku : "",
      quantity: product ? product.quantity : "",
      url: product ? product.url : "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    let formData = new FormData();

    formData.append("title", data.title);
    formData.append("handle", `${data.title}-handle`);
    formData.append("description", data.description);
    formData.append("price", data.price);
    formData.append("no_of_graphics", data.noOfGraphics);
    formData.append("compare_price", data.comparePrice);
    formData.append("shipping_price", data.shipPrice);
    formData.append("sku", data.sku);
    formData.append("quantity", data.quantity);
    formData.append("url", data.url);

    if (selectPrintprovider) {
      formData.append("print_provider_id", selectPrintprovider?.id);
    }

    if (images?.length) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images[]", images[i]);
      }
    }

    if (images.length > 0) {
      if (product) {
        updateCatalog(dispatch, formData, product.id, userType);
      } else {
        addCatalog(dispatch, formData, reset, userType);
      }
    } else {
      toastify("warning", "Please upload a file");
    }
  };

  function removeFile(identifier) {
    setImages((prevImages) => {
      // Remove by index
      if (identifier >= 0 && identifier < prevImages.length) {
        return prevImages.filter((_, index) => index !== identifier);
      } else {
        console.error("Index out of bounds");
        return prevImages;
      }
    });
  }

  const handleChange = (event) => {
    setSelectPrintprovider(event);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack spacing={2}>
          <TextField
            {...register("title")}
            size="small"
            label="Title"
            type="text"
            required
          />
          {errors.title?.message && (
            <Alert severity="error">{errors.title?.message}</Alert>
          )}

          <TextField
            {...register("description")}
            size="small"
            label="Description"
            type="text"
          />
          {errors.description?.message && (
            <Alert severity="error">{errors.description?.message}</Alert>
          )}

          <TextField
            {...register("noOfGraphics")}
            size="small"
            label="No. of Graphics"
            type="text"
            required
          />
          {errors.noOfGraphics?.message && (
            <Alert severity="error">{errors.noOfGraphics?.message}</Alert>
          )}

          <TextField
            {...register("price")}
            size="small"
            label="Price"
            type="text"
            required
          />
          {errors.price?.message && (
            <Alert severity="error">{errors.pricing?.message}</Alert>
          )}

          <TextField
            {...register("comparePrice")}
            size="small"
            label="Compare Price"
            type="text"
          />

          <TextField
            {...register("shipPrice")}
            size="small"
            label="Shipping Price"
            type="text"
          />
          {errors.shipPrice?.message && (
            <Alert severity="error">{errors.shipPrice?.message}</Alert>
          )}

          <TextField
            {...register("sku")}
            size="small"
            label="SKU"
            type="number"
          />
          {errors.sku?.message && (
            <Alert severity="error">{errors.sku?.message}</Alert>
          )}
          <TextField
            {...register("quantity")}
            size="small"
            label="Qty"
            type="number"
          />
          {errors.quantity?.message && (
            <Alert severity="error">{errors.quantity?.message}</Alert>
          )}

          <TextField
            {...register("url")}
            size="small"
            label="URL"
            type="string"
          />
        </Stack>

        <Typography variant="subtitle1" sx={{ my: 2 }}>
          Select Print Provider (optional)
        </Typography>

        <FormControl sx={{ width: "100%" }}>
          <Select
            options={options}
            onChange={handleChange}
            placeholder="Select..."
            isClearable
            defaultValue={
              product?.print_provider
                ? {
                    id: product?.print_provider?.id,
                    label: product?.print_provider?.name,
                    value: product?.print_provider?.uuid,
                  }
                : "Select..."
            }
            styles={styles}
          />
        </FormControl>

        <Typography variant="subtitle1" sx={{ my: 2 }}>
          Add Preview Image:
        </Typography>
        <StyledDropzone elevation={3} {...getRootProps()}>
          <input {...getInputProps()} multiple />
          <img src={LibraryImg} alt="library" style={{ width: 120 }} />
          <div>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Drag Your Files Here
            </Typography>
          </div>
        </StyledDropzone>

        <Stack direction={"row"} spacing={3} flexWrap={"wrap"} mt={3}>
          {images?.map((file, index) => {
            return (
              <DeleteImageViewer
                file={file}
                filename={file.name}
                deleteFile={() => removeFile(index)}
              />
            );
          })}
        </Stack>

        <StyledLoadingButton
          sx={{ marginTop: "30px" }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          loadingIndicator={<CircularProgress color="inherit" size={16} />}
        >
          {buttonTitle}
        </StyledLoadingButton>
      </form>
    </>
  );
}
