const defaultState = {
  educationContent: [],
  educationSections: [],
  showSectionInput: false,
  updates: [],
};

const educationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "setEducationContent":
      return {
        ...state,
        educationContent: action.payload,
      };

    case "setEducationSections":
      return {
        ...state,
        educationSections: action.payload,
      };

    case "setShowSectionInput":
      return {
        ...state,
        showSectionInput: action.payload,
      };

    case "setUpdates":
      return {
        ...state,
        updates: action.payload,
      };

    default:
      return state;
  }
};

export default educationReducer;
