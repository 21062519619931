import request from "src/utils/request";
import {
  setOrderList,
  setOrdersMeta,
  setRowsPerPage,
  setSearched,
  setTotalPages,
} from "src/redux/actions/orderActions";
import { loadingAction } from "src/redux/actions/userActions";

export const getOrdersByStatus = async (
  dispatch,
  tabName,
  page,
  rowsPerPage
) => {
  dispatch(loadingAction(true));

  try {
    const res = await request.get(
      `/orders/show/statuswise/seller?status=${tabName}&page=${page}&rowsPerPage=${rowsPerPage}`
    );

    if (res) {
   
        // dispatch(setOrderList([]));
      dispatch(setOrderList(res.data.data.orders.data));
      dispatch(setTotalPages(res.data.data.orders.meta.total));
      dispatch(setRowsPerPage(res.data.data.orders.meta.per_page));
      dispatch(setOrdersMeta(res.data.data.orders.meta));
      dispatch(setSearched(false));
      dispatch(loadingAction(false));
    }
  } catch (e) {
    dispatch(loadingAction(false));

    if (e.response.status === 404) {
      dispatch(setOrderList([]));
      dispatch(setTotalPages(1));
      dispatch(setRowsPerPage(25));
    }
  }
};
