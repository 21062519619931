import React, { useState } from "react";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

const birthstones = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const BirthStones = ({ selectedBirthStones, setBirthStones }) => {
  const handleChange = (event) => {
    const value = event.target.value;
    const newItem = { id: uuidv4(), name: value };
    if (selectedBirthStones?.length < 10) {
      setBirthStones([...selectedBirthStones, newItem]);
    }
  };

  const renderValue = (selected) => (
    <Typography variant="p">Total Selected: {selected?.length}</Typography>
  );

  const getItemCount = (item) =>
    selectedBirthStones?.filter((i) => i.name === item).length;

  const handleDelete = (idToDelete) => () => {
    const newArray = selectedBirthStones?.filter(
      (item) => item.id !== idToDelete
    );
    setBirthStones(newArray);
  };

  return (
    <Box mt={3}>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Select Birthstones:
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="birth_stone_id">Birthstones</InputLabel>
        <Select
          labelId="birth_stone_id"
          id="dropdown_id"
          value={
            typeof selectedBirthStones === "string" ? [] : selectedBirthStones
          }
          label="Birthstones"
          onChange={handleChange}
          MenuProps={{ style: { zIndex: 4000 } }}
          input={<OutlinedInput label="Select Items" />}
          renderValue={renderValue}
        >
          {birthstones?.map((stones, index) => (
            <MenuItem key={index} value={stones}>
              {stones} (
              {typeof selectedBirthStones === "string"
                ? 0
                : getItemCount(stones)}
              )
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ maxWidth: "500px" }}>
        {typeof selectedBirthStones === "string"
          ? ""
          : selectedBirthStones?.map((item, index) => (
              <>
                <Chip
                  key={index}
                  label={item?.name}
                  style={{ margin: 3, marginTop: "8px" }}
                  onDelete={handleDelete(item.id)}
                />
              </>
            ))}
      </Box>
    </Box>
  );
};

export default BirthStones;
