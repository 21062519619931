import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { getCredentials } from "./requests";
import { setModalState } from "src/redux/actions/modalActions";
import Iconify from "src/components/iconify";
import AddCredentials from "src/sections/modals/shipstation/AddCredentials";
import Icon from "src/components/svg-icons/icon";
import shipstaionIcon from "../../assets/shipstation.png";

const StyledContainer = styled(Card)(() => ({
  position: "relative",
  width: "auto",
  height: "fit-content",
  padding: "24px",
  borderRadius: "15px",
}));

const Item = ({ label, value }) => {
  return (
    <>
      <Typography variant="body1" component="span" sx={{ fontWeight: "bold" }}>
        {label}
      </Typography>

      <Typography
        variant="body1"
        sx={{
          backgroundColor: "aliceblue",
          padding: "8px 20px",
          borderRadius: "8px",
        }}
      >
        {value}
      </Typography>
    </>
  );
};
const ShipstationCredentials = () => {
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({});

  useEffect(() => {
    getCredentials(dispatch, setCredentials);
  }, [dispatch]);

  return (
    <Container maxWidth="xl" sx={{ margin: "0px", padding: "0px" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
        sx={{ mb: 5 }}
      >
        <Typography variant="h4">ShipStation Credentials</Typography>

        <Button
          variant={"contained"}
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() =>
            dispatch(
              setModalState(<AddCredentials setCredentials={setCredentials} />)
            )
          }
        >
          Add Credentials
        </Button>
      </Stack>

      {Object.keys(credentials).length === 0 ? (
        <Card>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "50vh" }}
          >
            <Grid item xs={3}>
              No Credentials Added
            </Grid>
          </Grid>
        </Card>
      ) : (
        <StyledContainer>
          <Stack spacing={2} mb={3}>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} alignItems="center">
                <Icon icon={shipstaionIcon} style={{ width: "50px" }} />
                <Typography variant="h6" sx={{ ml: 3 }}>
                  ShipStation
                </Typography>
              </Stack>
              <IconButton
                onClick={() =>
                  dispatch(
                    setModalState(
                      <AddCredentials
                        credentials={credentials}
                        setCredentials={setCredentials}
                      />
                    )
                  )
                }
              >
                <Iconify icon="eva:edit-fill" />
              </IconButton>
            </Stack>
            <Typography variant="p" sx={{ mb: 5 }}>
              ShipStation is a SaaS (Software as a Service) shipping platform
              that allows you to manage all your small parcel and LTL shipping
              needs in one place.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Item
              label={"Shipstation Key"}
              value={credentials?.shipstation_key}
            />
            <Item
              label={"Shipstation Secret"}
              value={credentials?.shipstation_secret}
            />
          </Stack>
        </StyledContainer>
      )}
    </Container>
  );
};

export default ShipstationCredentials;
