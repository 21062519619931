import React from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import LaptopIcon from "@mui/icons-material/Laptop";
import ResizeGraphic from "src/sections/modals/order/ResizeGraphic";
import DeleteImageViewer from "src/components/image-viewer/DeleteImageViewer";
import { isEmptyObject } from "src/utils/commonFunctions";

const UploadFile = ({
  uploadedFile,
  type,
  catalogItem,
  disableUpload,
  deleteFile,
}) => {
  const dispatch = useDispatch();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);

  const fileIsUploaded =
    uploadedFile?.src && !isEmptyObject(uploadedFile?.src) ? true : false;

  return fileIsUploaded ? (
    <DeleteImageViewer
      file={uploadedFile?.src}
      fileName={uploadedFile?.fileName}
      deleteFile={deleteFile}
      popup
    />
  ) : (
    <Button
      sx={{
        width: "100%",
        height: "100%",
        color: "#000",
        "&:hover": {
          backgroundColor: "#f3f3f3",
        },
      }}
      disabled={disableUpload}
      onClick={() =>
        dispatch(
          setModalState(
            <ResizeGraphic
              item={catalogDetails}
              place={type}
              catalogItem={catalogItem}
            />,
            true
          )
        )
      }
      startIcon={<LaptopIcon />}
    >
      Upload Image
    </Button>
  );
};

export default UploadFile;
