import { intervalToDuration, sub } from "date-fns";

export const formatDate = (date) => {
  const today = new Date();
  const orderDate = new Date(date);

  let interval = intervalToDuration({
    start: today,
    end: orderDate,
  });

  return sub(today, {
    years: interval.years,
    months: interval.months,
    days: interval.days,
    hours: interval.hours,
    minutes: interval.minutes,
    seconds: interval.seconds,
  });
};

export function getCurrentYear() {
  const date = new Date();
  return date.getFullYear(); //2024
}

export const getFormattedMonthYear = (item) => {
  // item looks like this {
  //   year: 2024,
  //   month: 8,
  //   totalRevenue: 49211.51,
  // }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return `${monthNames[item.month - 1]} ${item.year}`; // August 2024
};

export function formatDateByPattern(date, format) {
  //new Date(anyDate), "YYYY"
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const monthPadded = month.toString().padStart(2, "0");
  const dayPadded = day.toString().padStart(2, "0");

  if (format === "YYYY") {
    return `${year}`; // 2024
  } else if (format === "YYYY-MM") {
    return `${year}-${monthPadded}`; // 2024-08
  } else if (format === "YYYY-MM-DD") {
    return `${year}-${monthPadded}-${dayPadded}`; // 2024-08-16
  } else {
    return "Invalid format";
  }
}
