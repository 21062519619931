import request from "src/utils/request";
import { loadingAction } from "src/redux/actions/userActions";
import { toastify } from "src/utils/toast";

export const getCredentials = async (dispatch, setCredentials) => {
  dispatch(loadingAction(true));

  try {
    const res = await request.get("/print-provider/shipstation/credentials");
    if (res) {
      dispatch(loadingAction(false));
      setCredentials(res.data.data);
    }
  } catch (e) {
    dispatch(loadingAction(false));
    if (e.response.status === 404) {
      setCredentials([]);
    } else {
      toastify("error", e.response.data.message);
    }
  }
};
