import products from "../../../assets/icons/total_products.svg";
import ornaments from "../../../assets/icons/ornaments.svg";
import tumblers from "../../../assets/icons/tumblers.svg";
import frostedcup from "../../../assets/icons/frostedcup.svg";
import jewelry from "../../../assets/icons/Necklace.svg";
import bracelet from "../../../assets/icons/bracelet.svg";
import keychain from "../../../assets/icons/keychain.svg";
import onesie from "../../../assets/icons/onesie.svg";
import shirt from "../../../assets/icons/shirt.svg";
import initials from "../../../assets/icons/initials.svg";
import birthstones from "../../../assets/icons/birthstones.svg";

export const productStats = [
  {
    title: "Total Products Count",
    key: "total_products_count",
    icon: products,
  },
  { title: "Ornaments", key: "ornament", icon: ornaments },
  { title: "Tumblers", key: "tumbler", icon: tumblers },
  { title: "Frosted Cups", key: "frosted_cup", icon: frostedcup },
  { title: "Jewelry", key: "jewellery", icon: jewelry },
  { title: "Bracelet", key: "bracelet", icon: bracelet },
  { title: "Keychain", key: "keychain", icon: keychain },
  { title: "Initials", key: "Initials", icon: initials },
  { title: "Birthstones", key: "BirthStones", icon: birthstones },
  {
    title: "Gerber Organic Cotton Onesies",
    key: "Gerber_Organic_Cotton_Onesies_sold",
    icon: onesie,
  },
  {
    title: "Organic Cotton Toddler Shirt",
    key: "Organic_Cotton_Toddler_Shirt_sold",
    icon: shirt,
  },
  {
    title: "Organic Cotton Youth Shirt",
    key: "Organic_Cotton_Youth_Shirt_sold",
    icon: shirt,
  },
];
