import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { updateOrder } from "src/pages/orders/requests/icp/updateOrder";
import { loadingBtnAction } from "src/redux/actions/userActions";
import BirthStones from "./Birthstones";
import Initials from "./Initials";
import { isEmpty } from "lodash";
import {
  fillFormattedValues,
  getDefaultArray,
  productAttributes,
} from "./utils";

const UpdateVariations = ({ item }) => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);
  const loading = useSelector((state) => state.user.loadingButton);
  const getProduct = productAttributes?.find((s) => s.id === item.catalog?.id)
    ? productAttributes?.find((s) => s.id === item.catalog?.id)
    : {
        id: item.catalog?.id,
        name: item.catalog?.title,
        needsColor: false,
        needsSize: false,
        no_of_graphics: item?.catalog?.no_of_graphics,
        sizes: null,
      };
  const sizeArray = getProduct?.sizes;
  const default_array = getDefaultArray(getProduct);

  const [variations, setVariations] = useState([]);
  const [selectedColor, setSelectedColor] = useState("Gold");
  const [selectedSize, setSelectedSize] = useState(
    sizeArray ? sizeArray[0].value : 0
  );
  const [personalizationValue, setPersonalizationValue] = useState("");
  const [selectedBirthStones, setBirthStones] = useState([]);
  const [initials, setInitials] = useState([]);

  const initialsArray = item?.personalization_details
    ?.map((item) =>
      item?.formatted_name === "Initials" ? item?.formatted_value : []
    )
    .filter((item) => !isEmpty(item))[0];

  useEffect(() => {
    //set variations according to personalization_details
    setVariations(
      item?.personalization_details
        ? item?.personalization_details
        : default_array
    );
  }, []);

  useEffect(() => {
    //set default value
    if (variations && variations?.length) {
      let colorObj = variations.filter(
        (item) => item.formatted_name === "Color"
      )[0];
      let sizeObj = variations.filter(
        (item) => item.formatted_name === "Size"
      )[0];
      let personalizationObj = variations.filter(
        (item) => item.formatted_name === "Personalization"
      )[0];
      let birthstonesObj = variations.filter(
        (item) => item.formatted_name === "Birthstones"
      )[0];

      setSelectedColor(colorObj ? colorObj?.formatted_value : "Gold");
      setSelectedSize(
        sizeObj ? sizeObj?.formatted_value : sizeArray ? sizeArray[0].value : 0
      );
      setPersonalizationValue(
        personalizationObj ? personalizationObj?.formatted_value : ""
      );

      setBirthStones(birthstonesObj ? birthstonesObj?.formatted_value : []);
    } else {
      setSelectedColor("Gold");
      setSelectedSize(sizeArray ? sizeArray[0].value : 0);
      setBirthStones([]);
      setPersonalizationValue("");
    }
  }, [variations]);

  const handleSelectedColor = (event) => {
    setSelectedColor(event.target.value);
  };

  const handleSelectedSize = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleUpdate = () => {
    const updatedData = fillFormattedValues(
      default_array,
      selectedColor,
      selectedSize,
      personalizationValue,
      selectedBirthStones
    );

    let payload = { personalization_details: updatedData };

    dispatch(loadingBtnAction(true));
    updateOrder(
      {},
      item?.id,
      dispatch,
      userType,
      "",
      payload,
      initialsArray,
      initials
    );
  };

  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h4" sx={{ mb: 1.5 }}>
        Update Variations:
      </Typography>

      <Grid container>
        {getProduct?.needsSize && (
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
              Select Size:
            </Typography>
            <FormControl>
              <RadioGroup
                defaultValue={
                  variations && variations?.length
                    ? variations[1]?.formatted_value?.split(" ")[0]
                    : sizeArray
                    ? sizeArray[0].value
                    : 0
                }
                name="radio-buttons-group"
                value={selectedSize}
                onChange={handleSelectedSize}
              >
                {sizeArray?.map((size) => (
                  <FormControlLabel
                    value={size?.value}
                    control={<Radio />}
                    label={size?.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        )}

        {getProduct?.needsColor && (
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
              Select Color:
            </Typography>
            <FormControl>
              <RadioGroup
                defaultValue={
                  variations && variations?.length
                    ? variations[0].formatted_value
                    : "Gold"
                }
                name="radio-buttons-group"
                value={selectedColor}
                onChange={handleSelectedColor}
              >
                <FormControlLabel
                  value="Gold"
                  control={<Radio />}
                  label="Gold"
                />
                <FormControlLabel
                  value="Silver"
                  control={<Radio />}
                  label="Silver"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
      </Grid>

      {item?.catalog?.no_of_graphics === 2 && (
        <>
          <BirthStones
            selectedBirthStones={selectedBirthStones}
            setBirthStones={setBirthStones}
          />
          <Initials initials={initials} setInitials={setInitials} />
        </>
      )}

      <Box sx={{ mt: 1 }}>
        <Typography variant="subtitle1">Personalization:</Typography>
        <TextField
          id="personalization_input"
          label="Personalization"
          multiline
          maxRows={4}
          fullWidth
          sx={{ mt: 2 }}
          value={personalizationValue}
          onChange={(event) => {
            setPersonalizationValue(event.target.value);
          }}
        />
      </Box>
      <Box sx={{ textAlign: "end", mt: 3 }}>
        <LoadingButton
          variant="contained"
          loading={loading}
          loadingIndicator={<CircularProgress size={12} color="inherit" />}
          onClick={handleUpdate}
        >
          Update
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default UpdateVariations;
