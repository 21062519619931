import React, { useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import ICPTOS from "../../assets/ICP_TOS.pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page } from "react-pdf";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

const TermsOfService = () => {
    const [numPages, setNumPages] = useState();

  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
  
  return (
    <Paper elevation={3} style={{ padding: "20px 200px", margin: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Last updated December 31, 2023
      </Typography>
      <Box sx={{ mb: 4 }}>
        <div className="pdf-div">
          <Document file={ICPTOS} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => {
                return (
                  <Page
                    pageNumber={page}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                );
              })}
          </Document>
        </div>
      </Box>
    </Paper>
  );
};

export default TermsOfService;
