import request from "src/utils/request";
import { getAllOrdersList } from ".";
import { toastify } from "src/utils/toast";
import { loadingBtnAction } from "src/redux/actions/userActions";
import { setModalState } from "src/redux/actions/modalActions";

export async function createInitialsFile(url) {
  let response = await fetch(url);
  let data = await response.blob();
  let metadata = {
    type: "image/jpeg",
  };
  let file = new File([data], "test.jpg", metadata);
  return file;
}

export const updateInitials = async (
  dispatch,
  id,
  initialsArray,
  userType,
  initials
) => {
  //update initials of jewelry on variations
  const page =
    sessionStorage.getItem("page") !== null
      ? sessionStorage.getItem("page")
      : 1;

  const rowsPerPage =
    sessionStorage.getItem("rowsPerPage") !== null
      ? sessionStorage.getItem("rowsPerPage")
      : 25;

  const tabValue = sessionStorage.getItem("activeTabIndex")
    ? sessionStorage.getItem("activeTabIndex")
    : "1";

  let formData = new FormData();

  initials.forEach((image, index) => {
    formData.append(`initials[${index}]`, image);
  });

  try {
    const res = await request.post(`/orders/update/initials/${id}`, formData);

    if (res) {
      getAllOrdersList(dispatch, userType, page, rowsPerPage, tabValue);
      dispatch(setModalState(undefined));
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};
