import { LoadingButton } from "@mui/lab";
import { CircularProgress, styled } from "@mui/material";
import React from "react";

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  maxWidth: "350px",
  borderRadius: "40px",
}));

const StyledButton = ({ title, loading, disabled = false, sx, ...props }) => {
  return (
    <StyledLoadingButton
      {...props}
      className="mt-3"
      variant="contained"
      size={"medium"}
      disabled={disabled}
      loading={loading}
      sx={{ borderRadius: "20px", ...sx }}
      loadingIndicator={<CircularProgress color="inherit" size={16} />}
    >
      {title}
    </StyledLoadingButton>
  );
};

export default StyledButton;
