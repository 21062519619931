import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { setModalState } from "src/redux/actions/modalActions";
import { setProcessingTime } from "src/redux/actions/productActions";
import { loadingAction, loadingBtnAction } from "src/redux/actions/userActions";

export const getProcessingTime = async (dispatch) => {
  dispatch(loadingAction(true));
  try {
    const res = await request.get("/processingtime");

    if (res) {
      dispatch(loadingAction(false));
      dispatch(setProcessingTime(res.data.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      dispatch(setProcessingTime([]));
    }
    toastify("error", e.response.data.message);
    dispatch(loadingAction(false));
  }
};

export const addProcessingTime = async (dispatch, payload) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post("/processingtime", payload);

    if (res) {
      dispatch(loadingBtnAction(false));
      dispatch(setModalState(undefined));
      getProcessingTime(dispatch);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};

export const updateProcessingTime = async (dispatch, payload, id) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post(`/processingtime/${id}`, payload);

    if (res) {
      dispatch(loadingBtnAction(false));
      dispatch(setModalState(undefined));
      getProcessingTime(dispatch);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};

export const deleteProcessingTime = async (dispatch, id) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.delete(`/processingtime/${id}`);

    if (res) {
      dispatch(loadingBtnAction(false));
      dispatch(setModalState(undefined));
      getProcessingTime(dispatch);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};
