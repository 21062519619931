export const formatData = (data) => {
  const result = [];

  // URLs
  if (data.url && data.url.length > 0) {
    result.push({
      title: data.url ? data.url[0].name : "Education Content URLs",
      icon: "bi:link",
      type: "url",
      data: data.url.map((item) => item.media),
    });
  }

  // Images (PNG + JPG)
  const images = [...(data.png || []), ...(data.jpg || [])];

  if (images.length > 0) {
    result.push({
      title: images ? images[0].name : "Education Images Content",
      icon: "ic:outline-image",
      type: "image",
      data: images.map((item) => item.media),
    });
  }

  if (data.pdf && data.pdf.length > 0) {
    result.push({
      title: data.pdf ? data.pdf[0].name : "Education PDF Content",
      icon: "prime:file-pdf",
      type: "doc",
      data: data.pdf.map((item) => item.media),
    });
  }

  if (data.psd && data.psd.length > 0) {
    result.push({
      title: data.psd ? data.psd[0].name : "Education PSD Content",
      icon: "logos:adobe-photoshop",
      type: "doc",
      data: data.psd.map((item) => item.media),
    });
  }

  return result;
};

export function categorizeMediaItems(items) {
  const categorizedData = {
    images: [],
    docs: [],
    mp4: [],
    urls: [],
  };

  items?.forEach((item) => {
    const { type } = item;
    switch (type) {
      case "mp4":
        categorizedData.mp4.push(item);
        break;
      case "url":
        categorizedData.urls.push(item);
        break;
      case "psd":
      case "pdf":
        categorizedData.docs.push(item);
        break;
      case "png":
      case "jpg":
        categorizedData.images.push(item);
        break;
      default:
        // Handle any unexpected type or implement additional categorization if needed
        break;
    }
  });

  return categorizedData;
}
