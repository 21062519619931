import React from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";

const ListDocument = ({ title, desc, onClickItem, onDelete, sx }) => {
  return (
    <Paper variant="outlined" sx={{...sx}}>
      <List>
        <ListItem
          disablePadding
          secondaryAction={
            onDelete ? (
              <IconButton color="error" onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            ) : (
              ""
            )
          }
        >
          <ListItemButton onClick={onClickItem}>
            <ListItemIcon>
              <FolderIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={title} secondary={desc} />
          </ListItemButton>
        </ListItem>
      </List>
    </Paper>
  );
};

export default ListDocument;
