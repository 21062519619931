import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { LoadingButton } from "@mui/lab";
import { addProcessingTime } from "src/pages/dashboard/processing-time/request";

const SetProcessingTime = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loadingButton);
  const catalogList = useSelector((state) => state.orders.catalogList);
  const [selectedCatalog, setSelectedCatalog] = useState("");

  const handleChange = (event) => {
    setSelectedCatalog(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      type: selectedCatalog,
      processing_time: e.target.elements.time.value,
    };
    addProcessingTime(dispatch, payload);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Add Processing Time
      </Typography>

      <Box sx={{ my: 2 }} />
      <FormControl fullWidth>
        <InputLabel id="catalog">Catalog</InputLabel>
        <Select
          labelId="catalog"
          id="selectCatalog"
          value={selectedCatalog}
          label="Catalog"
          onChange={handleChange}
        >
          {catalogList?.map((item) => (
            <MenuItem value={item.title}>{item.title}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <form onSubmit={handleSubmit}>
        <Box sx={{ my: 2 }} />
        <TextField
          fullWidth
          type="text"
          label="Processing Time"
          name="time"
          required
        />

        <Box sx={{ my: 2 }} />
        <Stack direction="row" justifyContent={"end"}>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => dispatch(setModalState(undefined))}
          >
            Cancel
          </Button>
          <Box sx={{ ml: 2 }} />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
          >
            Add
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default SetProcessingTime;
