import React, { useState } from "react";
import ICPTOS from "../../../assets/ICP_TOS.pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import request from "src/utils/request";
import { useDispatch } from "react-redux";
import { toastify } from "src/utils/toast";
import { Document, Page } from "react-pdf";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";
import { setModalState } from "src/redux/actions/modalActions";
import { loadingBtnAction } from "src/redux/actions/userActions";
import {
  Box,
  Button,
  CardActions,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

const TermsOfService = ({ email }) => {
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState();
  const [inputEmail, setEmail] = useState("");

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleAccept = async () => {
    const payload = { email: email === "" ? inputEmail : email };

    dispatch(loadingBtnAction(true));
    try {
      const res = await request.post("/tos/accept", payload);

      if (res) {
        dispatch(loadingBtnAction(false));
        dispatch(setModalState(undefined));
        toastify("success", res.data.message);
      }
    } catch (e) {
      toastify("error", e.message);
      dispatch(loadingBtnAction(false));
    }
  };

  return (
    <>
      <Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
        <Typography variant="h4" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Last updated December 31, 2023
        </Typography>
        <Box sx={{ mb: 4 }}>
          <div className="pdf-div">
            <Document file={ICPTOS} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page) => {
                  return (
                    <Page
                      pageNumber={page}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  );
                })}
            </Document>
          </div>
        </Box>

        {email === "" && (
          <TextField
            type="email"
            label="Enter your Email"
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        )}
      </Paper>

      <CardActions style={{ justifyContent: "center", padding: "16px" }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => dispatch(setModalState(undefined))}
        >
          Decline
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={email === "" && inputEmail === "" ? true : false}
          style={{ marginLeft: "8px" }}
          onClick={handleAccept}
        >
          Accept
        </Button>
      </CardActions>
    </>
  );
};

export default TermsOfService;
