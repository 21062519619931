import React from "react";
import LinkIcon from "@mui/icons-material/Link";
import { FormControl, InputAdornment, TextField } from "@mui/material";

const AddURL = ({ urlValue, onChange, disabled, urlError }) => {
  return (
    <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
      <TextField
        placeholder="Add Canva, Kittl or Adobe URL"
        variant="outlined"
        type="text"
        disabled={disabled}
        value={urlValue}
        onChange={onChange}
        error={urlError}
        helperText={urlError ? "Please enter a valid URL" : " "}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LinkIcon />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default AddURL;
