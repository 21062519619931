import React from "react";
import { CardMedia, Stack, Typography } from "@mui/material";
import Iconify from "../../../../components/iconify/Iconify";
import { StyledStack } from "src/pages/dashboard/utils";

const ShowContent = ({ file, type, index }) => {
  return (
    <>
      {type === "mp4" ? (
        <CardMedia
          component="video"
          controls
          src={URL.createObjectURL(file)}
          height={130}
          alt="Your Video"
        />
      ) : type === "png" || type === "jpg" ? (
        <CardMedia
          key={index}
          component={"img"}
          image={URL.createObjectURL(file)}
          alt={file.name}
          sx={{ width: "80px", height: "80px" }}
        />
      ) : type === "pdf" || type === "psd" ? (
        <StyledStack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"row"} spacing={2} sx={{ width: "200px" }}>
            <Iconify
              icon="teenyicons:text-document-solid"
              color="primary.main"
            />
            <Typography variant="body2">
              File-{index + 1}.{type}
            </Typography>
          </Stack>
        </StyledStack>
      ) : (
        ""
      )}
    </>
  );
};

export default ShowContent;
