import { Stack, styled } from "@mui/material";

export function myClock() {
  setTimeout(function () {
    const d = new Date();
    const n = d.toLocaleTimeString();
    if (document.getElementById("time")) {
      document.getElementById("time").innerHTML = n;
    }
    myClock();
  }, 1000);
}

export function getMonthName(monthNumber) {
  const date = new Date(0, monthNumber - 1);
  return date.toLocaleString("en-US", { month: "long" });
}

export const formatStats = (stats, type) => {
  const combinedData = {};
  if (type === "year") {
    stats?.admin_revenue?.forEach(({ month, total_revenue }) => {
      if (!combinedData[month])
        combinedData[month] = {
          month: getMonthName(month),
          total_revenue: 0,
          total_products_launched: 0,
        };
      combinedData[month].total_revenue += parseFloat(total_revenue);
    });

    stats?.product_counts?.forEach(({ month, total_products_launched }) => {
      if (!combinedData[month])
        combinedData[month] = {
          month: getMonthName(month),
          total_revenue: 0,
          total_products_launched: 0,
        };
      combinedData[month].total_products_launched += total_products_launched;
    });
  } else {
    stats?.admin_revenue?.forEach(({ date, total_revenue }) => {
      if (!combinedData[date])
        combinedData[date] = {
          date: date,
          total_revenue: 0,
          total_products_launched: 0,
        };
      combinedData[date].total_revenue += parseFloat(total_revenue);
    });

    stats?.product_counts?.forEach(({ date, total_products_launched }) => {
      if (!combinedData[date])
        combinedData[date] = {
          date: date,
          total_revenue: 0,
          total_products_launched: 0,
        };
      combinedData[date].total_products_launched += total_products_launched;
    });
  }

  return Object.values(combinedData).sort((a, b) => a.month - b.month);
};

export const StyledStack = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  padding: "8px",
  borderRadius: "16px",
}));
