import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import scope_icon from "../../../assets/icons/navbar/ic_lock.svg";
import { useDispatch, useSelector } from "react-redux";
import request from "src/utils/request";
import { setModalState } from "src/redux/actions/modalActions";
import { getCredentials } from "src/pages/shipstation/requests";
import { loadingBtnAction } from "src/redux/actions/userActions";
import { toastify } from "src/utils/toast";
import { LoadingButton } from "@mui/lab";

const AddCredentials = ({ credentials, setCredentials }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loadingButton);

  const getFormValue = (e) => {
    e.preventDefault();
    let payload = {
      shipstation_key: e.target.elements.key.value,
      shipstation_secret: e.target.elements.secret.value,
    };

    addCredentials(payload);
  };

  const addCredentials = async (payload) => {
    dispatch(loadingBtnAction(true));
    try {
      const res = credentials
        ? await request.patch(
            `/print-provider/shipstation/credentials/${credentials.id}`,
            payload
          )
        : await request.post(
            "/print-provider/shipstation/credentials",
            payload
          );
      if (res) {
        toastify("success", res.data.message);
        dispatch(loadingBtnAction(false));
        dispatch(setModalState(undefined));
        getCredentials(dispatch, setCredentials);
      }
    } catch (e) {
      toastify("error", e.response.data.message);
      dispatch(loadingBtnAction(false));
    }
  };

  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
    >
      <img height={80} width={80} src={scope_icon} alt="wallet icon" />
      <Typography variant="h4" color={"primary.main"} gutterBottom>
        Add ShipStation Credentials
      </Typography>
      <form onSubmit={(e) => getFormValue(e)} style={{ width: "80%" }}>
        <Stack spacing={2}>
          <TextField
            type="text"
            label="ShipStation Key"
            name="key"
            defaultValue={credentials ? credentials?.shipstation_key : ""}
            fullWidth
            required
          />
          <TextField
            type="text"
            label="ShipStation Secret"
            name="secret"
            defaultValue={credentials ? credentials?.shipstation_secret : ""}
            required
            fullWidth
          />
        </Stack>
        <Stack direction="row" justifyContent={"center"} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="inherit"
            fullWidth
            onClick={() => dispatch(setModalState(undefined))}
          >
            Cancel
          </Button>
          <Box sx={{ ml: 2 }} />
          <LoadingButton
            variant="contained"
            type="submit"
            fullWidth
            loading={isLoading}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
};

export default AddCredentials;
