import React from "react";
import { Alert, Typography } from "@mui/material";
import {
  compareDimensions,
  getImageDimension,
  dimensionsArray,
} from "src/sections/modals/order/dimensions";

const Validations = ({ item }) => {
  let currentItem = dimensionsArray.find((d) => d.id === item?.catalog?.id);
  let notecardItem = dimensionsArray.find((d) => d.id === 111);

  const graphicImageDimensions = item.graphic_image
    ? getImageDimension(item?.graphic_image)
    : "";

  const notecardImageDimension = item?.notecard_image
    ? getImageDimension(item?.notecard_image)
    : "";

  let graphicValidator = item.graphic_image
    ? compareDimensions(graphicImageDimensions, currentItem)
    : "";

  let notecardValidator = item?.notecard_image
    ? compareDimensions(notecardImageDimension, notecardItem)
    : "";
  return (
    <div>
      {" "}
      <Typography variant="body2">
        <b>Standard Graphic Dimensions:</b>
        <br />
        {currentItem?.width === null ? 0 : currentItem?.width}
        px x {currentItem?.height === null ? 0 : currentItem?.height}
        px.
      </Typography>
      <Typography variant="body2" mt={1}>
        <b>Graphic Dimensions:</b> <br />{" "}
        {graphicImageDimensions?.width === null ||
        graphicImageDimensions?.height === null
          ? "NA"
          : `${graphicImageDimensions?.width}px x ${graphicImageDimensions?.height}px`}
      </Typography>
      {graphicImageDimensions?.width === null ||
      graphicImageDimensions?.height === null ? (
        ""
      ) : graphicValidator ? (
        <Alert severity="success">Correct</Alert>
      ) : (
        <Alert severity="error">Wrong</Alert>
      )}
      {item?.notecard_image && (
        <>
          <Typography variant="body2">
            <b>Standard Notecard Dimensions:</b>
            <br />
            {notecardItem?.width === null ? 0 : notecardItem?.width}
            px x {notecardItem?.height === null ? 0 : notecardItem?.height}
            px.
          </Typography>

          <Typography variant="body2" mt={1}>
            <b>Notecard Dimensions:</b> <br />{" "}
            {notecardImageDimension?.width === null ||
            notecardImageDimension?.height === null
              ? "NA"
              : `${notecardImageDimension?.width}px x ${notecardImageDimension?.height}px`}
          </Typography>
          {notecardImageDimension?.width === null ||
          notecardImageDimension?.height === null ? (
            ""
          ) : notecardValidator ? (
            <Alert severity="success">Correct</Alert>
          ) : (
            <Alert severity="error">Wrong</Alert>
          )}
        </>
      )}
    </div>
  );
};

export default Validations;
