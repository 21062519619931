import React from "react";
import Iconify from "src/components/iconify/Iconify";
import DeleteContent from "src/sections/modals/education/DeleteContent";
import AddContent from "src/sections/modals/education/add-content/AddContent";
import { useDispatch } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { Box, Grid, IconButton, Paper, useTheme } from "@mui/material";
import ImageViewPopup from "src/components/image-viewer/ImageViewPopup";

const EducationImages = ({ item }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper
        elevation={3}
        sx={{
          position: "relative",
          width: "120px",
          height: "120px",
        }}
      >
        <ImageViewPopup
          imageSRC={[item?.media]}
          fileName={item?.name}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "16px",
          }}
        />
        <Box sx={{ position: "absolute", top: 0, right: 0, m: 1 }}>
          <IconButton
            aria-label="download"
            sx={{
              backgroundColor: theme.palette.primary.main,
              opacity: "80%",
              color: "#fff",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
            onClick={() => {
              window.open(item?.media, "_blank");
            }}
          >
            <Iconify icon="material-symbols:download" />
          </IconButton>

        </Box>
          <IconButton
              aria-label="edit"
              onClick={() =>
                dispatch(setModalState(<AddContent item={item} edit/>))
              }
            >
              <Iconify icon="eva:edit-fill" color="primary.main" />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() =>
                dispatch(setModalState(<DeleteContent id={item.id} />))
              }
            >
              <Iconify icon="eva:trash-2-outline" color="error.main" />
            </IconButton>
      </Paper>
    </Grid>
  );
};

export default EducationImages;
