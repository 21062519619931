export const page =
  sessionStorage.getItem("page") !== null ? sessionStorage.getItem("page") : 1;

export const rowsPerPage =
  sessionStorage.getItem("rowsPerPage") !== null
    ? sessionStorage.getItem("rowsPerPage")
    : 25;

export const tabValue = sessionStorage.getItem("activeTabIndex")
  ? sessionStorage.getItem("activeTabIndex")
  : "1";
