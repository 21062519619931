import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ProductsBarChart = ({ stats }) => {
  const chartData = [
    {
      name: "Ornaments",
      etsy: stats?.ornaments_sold?.etsy_sold,
      amazon: stats?.ornaments_sold?.amazon_sold,
      local: stats?.ornaments_sold?.local_sold,
    },
    {
      name: "Tumblers",
      etsy: stats?.tumblers_sold?.etsy_sold,
      amazon: stats?.tumblers_sold?.amazon_sold,
      local: stats?.tumblers_sold?.local_sold,
    },
    {
      name: "Frosted Cups",
      etsy: stats?.frosted_cup_sold?.etsy_sold,
      amazon: stats?.frosted_cup_sold?.amazon_sold,
      local: stats?.frosted_cup_sold?.local_sold,
    },
    {
      name: "Jewelry",
      etsy: stats?.jewellry_sold?.etsy_sold,
      amazon: stats?.jewellry_sold?.amazon_sold,
      local: stats?.jewellry_sold?.local_sold,
    },
    {
      name: "Bracelet",
      etsy: stats?.bracelet_sold?.etsy_sold,
      amazon: stats?.bracelet_sold?.amazon_sold,
      local: stats?.bracelet_sold?.local_sold,
    },
    {
      name: "keychain",
      etsy: stats?.keychain_sold?.etsy_sold,
      amazon: stats?.keychain_sold?.amazon_sold,
      local: stats?.keychain_sold?.local_sold,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="etsy" stackId="a" fill="#02B2FE" name="Etsy" />
        <Bar dataKey="amazon" stackId="a" fill="#82ca9d" name="Amazon" />
        <Bar dataKey="local" stackId="a" fill="#76B0F1" name="Local" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ProductsBarChart;
