import React, { useState } from "react";
import {
  Box,
  Button,
  CardActions,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

const TaxDocument = ({ fileURL }) => {
  const [numPages, setNumPages] = useState();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Resellers Tax Permit
      </Typography>

      <Box sx={{ mb: 4 }}>
        <div className="pdf-div">
          <Document file={fileURL} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => {
                return (
                  <Page
                    pageNumber={page}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                );
              })}
          </Document>
        </div>
      </Box>
    </Paper>
  );
};

export default TaxDocument;
